<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.role'),

            filter: {
                slug: { value: null, type: 'text'},
                title: { value: null, type: 'text'},
            },
            tableHeader: [
                //{ label: '#', is_sort: false },
                { key: 'id', is_sort: true },
                { key: 'slug', is_sort: true, classes: 'text-l' },
                { key: 'title', is_sort: true, classes: 'text-l' },
            ],

            apiList: this.$store.getters.getApiUrl('role'),
            pageRoute: 'role',
            pageRouteCreate: 'role.create',
            pageRouteEdit: 'role.edit',
            pagePermission: 'user.role',
        }
    },
}
</script>

<style scoped>

</style>
