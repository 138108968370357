export default {
    title_list: 'Должности сотрудников',
    title_page: 'Должность сотрудника',
    title_create_page: 'Создать должность сотрудника',
    title_edit_page: 'Изменить должность сотрудника',

    filter: {
        labels: {
            title: 'Название',
        }
    },

    table: {
        labels: {
            id: 'ID',
            title: 'Название',
        }
    },

    buttons: {

    },

    form: {
        title: 'Название',
    },
}
