export default {
    title_list: 'Проекты',
    title_page: 'Проект',
    title_create_page: 'Создать проект',
    title_edit_page: 'Изменить проект',

    filter: {
        labels: {
            title: 'Название',
            user_id: 'Ответственное лицо',
            status_id: 'Статус проекта',
        }
    },

    table: {
        labels: {
            id: 'ID',
            status_id: 'Статус',
            title: 'Название',
            user_id: 'Ответственный',
            contract: 'Договор',
            end_date: 'Срок сдачи',
            note: 'Примечание',
        }
    },

    buttons: {

    },

    form: {
        status_id: 'Статус проекта',
        user_id: 'Ответственное лицо',
        title: 'Название проекта',
        end_date: 'Дата окончания',
        note: 'Примечание',
    },
}
