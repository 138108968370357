<template>
    <div>Доступ к разделу ограничен</div>
</template>

<script>
export default {
    name: "PermissionDenied"
}
</script>

<style scoped>

</style>
