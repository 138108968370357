<template>
    <div :class="['slideModal', {'open' : open}]">
        <div class="slideModalClose" @click="returnBack()"></div>
        <div class="modalContainer">
            <div class="head">
                <div class="title">
                    {{ titleModal }}
                </div>
            </div>

            <div class="body" style="height: 100%;">
                <div class="back" style="margin-bottom: 10px">
                    <div class="icon" @click="returnBack()"></div>
                </div>

                <template v-if="file.extension === 'pdf'">
                    <object :data="file.original_link" type="application/pdf" width="100%" height="100%">
                        <p>Просмотр {{ file.title }} не поддерживается вашим браузером. Скачайте его по ссылке <a target="_blank" :href="file.url">скачать файл.</a></p>
                    </object>
                </template>
                <template v-if="file.extension === 'txt'">
                    <object :data="file.original_link" type="text/plain" width="100%" height="100%">
                        <p>Просмотр {{ file.title }} не поддерживается вашим браузером. Скачайте его по ссылке <a target="_blank" :href="file.url">скачать файл.</a></p>
                    </object>
                </template>
                <template v-if="['jpg', 'jpeg', 'png', 'bmp', 'gif'].includes(file.extension)">
                    <img :src="file.url" alt="" style="max-width: 100%; max-height: 100%;">
                </template>
<!--                <iframe style="width: 100%; border: none; height: 100%;" :src="getSource()" v-if="file.id"></iframe>-->
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PreviewFile",
    components: {},
    props: {
        modalFile: {
            type: Object,
            default: () => { return {title: null} }
        },
        modalOpen: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            file: this.modalFile,
            open: this.modalOpen,
            loading: false,
        }
    },
    watch: {
        modalFile(val) {
            this.file = val
        },
        modalOpen(val) {
            this.open = val
        }
    },
    computed: {
        titleModal() {
            if (this.file && this.file.id) {
                return `Предпросмотр файла "${ this.file.title }" (ID ${ this.file.id })`
            }
            return 'Предпросмотр файла'
        }
    },
    methods: {
        getSource() {
            let url = this.file.url
            return url
            //return `https://docs.google.com/gview?url=${ url }&embedded=true`
        },

        returnBack() {
            this.open = false
            this.file = null
            this.$emit('close')
        },
    }
}
</script>


<style scoped>

</style>
