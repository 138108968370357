<template>
    <div>
        <!-- Файловый менеджер -->
        <div class="block">
            <div class="title">Файловый менеджер</div>
            <div class="body">
                <button :class="['button', 'btn-primary']" @click="openModalWindowFolder()" v-if="availableCreate">
                    Добавить папку
                </button>
                <button :class="['button', 'btn-primary']" @click="openModalSelectFile()" v-if="availableCreate">
                    Загрузить файл(ы)
                </button>
                <button :class="['button', 'btn-primary']" @click="openModalSelectFile('folder')" v-if="availableCreate">
                    Загрузить папку
                </button>

                <div class="fileManager tableView">

                    <div class="back">
                        <div class="text" v-for="item in path" @click="returnBack(item)" :title="item.title">
                            {{ item.title }}
                        </div>
                    </div>

                    <div class="headerInfo">
                        <div class="info" v-for="item in headerInfo" @click="sortFolder(item)">
                            {{ item.title }}
                            <span v-if="item.availableOrder" :class="['icon ico-fa', { 'icon-sort' : !item.order }, { 'icon-asc' : item.order === 'asc' }, { 'icon-desc' : item.order === 'desc' }] "></span>
                        </div>
                    </div>

                    <div class="file" v-if="parentFolder">
                        <div class="info" :title="parentFolder.title"  @click="returnBack(parentFolder)">
                            <div :class="['icon', 'ico-fa4', 'icon-folder']"></div>
                            <div class="text">
                                ...
                            </div>
                        </div>
                    </div>

                    <div class="file" v-for="item in files" v-if="getReadUserPermission(item)">
                        <div class="info" :title="item.title" @click="openFile(item)">
                            <div :class="['icon', 'ico-fa4', 'icon-'+getIconByExtension(item)]"></div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                        </div>

                        <div class="info" :title="dayjs(item.updated_at).format('DD.MM.YYYY HH:mm:ss')">
                            <div class="text">
                                {{ dayjs(item.updated_at).format('DD.MM.YYYY HH:mm:ss') }}
                            </div>
                        </div>

                        <div class="info" :title="item.user ? item.user.short_name : ''">
                            <div class="text">
                                {{ item.user ? item.user.short_name : '' }}
                            </div>
                        </div>

                        <div class="info">
                            <div class="text" v-if="item.text_type === 'file'">
                                {{ item.size ? item.size + 'Мб' : '' }}
                            </div>
                        </div>


                        <div class="info">
                            <div class="text" v-if="item.text_type === 'file'">
                                {{ item.extension ? item.extension : '' }}
                            </div>
                        </div>

                        <div class="actions" v-if="getWriteUserPermission(item)">
                            <span class="icon ico-i icon-view"
                                  v-if="item.text_type === 'file' && ['txt', 'pdf', 'image'].includes(getIconByExtension(item))"
                                  @click="openModalWindowPreview(item)"></span>
                            <span class="icon ico-i icon-edit" @click="openModalWindowItem(item)"></span>
                            <span class="icon ico-i icon-delete" @click="openConfirmDeleteFileModal(item)"></span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div :class="['loadingModal', {'active' : loading}]">
            <div>
                <img src="/images/dual_loader.gif" alt="">
                <span v-if="loadingText">{{ loadingText }}</span>
            </div>
        </div>


        <ModalWindow :open="openModalFiles"
                     @closeModal="openModalFiles = false">
            <template slot="title">
                Выбрать файлы
            </template>
            <template slot="body">
                <div class="drag-in-drop"
                     @dragover="dragoverFile"
                     @dragleave="dragleaveFile"
                     @drop="(e) => dropFile(e, 'documents')"
                     @click="$refs.document.click()">
                    <input type="file" :webkitdirectory="openModalFilesType === 'folder'"
                           @change="onChangeFile('documents')"
                           ref="document"
                           multiple
                           :accept="acceptDocuments" />
                    <div class="label">
                        <template v-if="openModalFilesType === 'folder'">
                            Кликните для выбора папки
                        </template>
                        <template v-else>
                            Перетащите файлы или кликните для выбора
                        </template>
                    </div>
                </div>
                <div class="documents">
                    <div class="table-scroll__wrapper">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="w80" v-if="openModalFilesType === 'folder'">Путь</th>
                                <th class="w80">Файл</th>
                                <th class="w10">Размер</th>
                                <th class="w10">#</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(document,index) in selectedFiles"
                                :key="index">
                                <td class="text-l" v-if="openModalFilesType === 'folder'">
                                    {{ document.pathName ? document.pathName.join('/') : '-' }}
                                </td>
                                <td class="text-l">
                                    <template v-if="document.url">
                                        <a class="link"
                                           :href="document.url"
                                           target="_blank">
                                            {{ document.title }}
                                        </a>
                                    </template>
                                    <template v-else>
                                        <input type="text" class="input" v-model="document.title">
                                    </template>
                                </td>
                                <td class="text-l">
                                    {{ document.size }}
                                </td>
                                <td>
                                    <button class="button btn-danger" @click="deleteSelectedDocument(index)">
                                        <span class="icon ico-i icon-delete"></span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template slot="buttons">
                <button class="button btn-success" @click="addFiles">
                    Загрузить выбранные файлы
                </button>
            </template>
        </ModalWindow>



        <select-folder
            :project-id="projectId"
            :modal-parent-folder="modalParentFolder"
            :modal-folder="modalFolder"
            :modal-open="slideModal"
            @updateRootFolders="(data) => syncFolder()"
            @close="slideModal = false; modalFolder = { title: null }"
        >
        </select-folder>

        <select-file
            :project-id="projectId"
            :modal-open="slideModalFile"
            :modal-file="modalFile"
            @updateRootFolders="(data) => syncFolder()"
            @close="slideModalFile = false; modalFile = { title: null }"
        >
        </select-file>


        <preview-file
            :modal-file="modalPreviewFile"
            :modal-open="slideModalPreviewFile"
            @close="slideModalPreviewFile = false; modalPreviewFile = { title: null }"
        ></preview-file>

        <ModalWindow :open="openConfirmDeleteFile"
                     @closeModal="openConfirmDeleteFile = false">
            <template slot="title">
                Удаление файла/папки
            </template>
            <template slot="body">
                Вы уверены, что хотите удалить элемент с именем "{{ objectConfirmDeleteFile ? objectConfirmDeleteFile.title : '' }}"?
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteItem(objectConfirmDeleteFile)">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

    </div>
</template>

<script>
import ModalWindow from "../../Utils/ModalWindow.vue";
import Multiselect from "vue-multiselect";
import SelectFolder from "./SelectFolder.vue";
import SelectFile from "./SelectFile.vue";
import PreviewFile from "./PreviewFile.vue";
import dayjs from "dayjs";

export default {
    name: "FileManager",
    components: {
        PreviewFile,
        SelectFile,
        SelectFolder,
        ModalWindow,
        Multiselect
    },
    props: {
        projectId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            openConfirmDeleteFile: false,
            objectConfirmDeleteFile: null,

            parentFolder: null,
            folder: {},
            folders: [],
            path: [{
                id: 'root',
                title: 'Корневая директория'
            }],
            loading: false,
            loadingText: null,

            word: ['doc', 'docx'],
            excel: ['xls', 'xlsx'],
            pdf: ['pdf'],
            txt: ['txt'],
            image: ['jpg', 'jpeg', 'png', 'bmp', 'gif'],
            archive: ['zip', 'rar', 'gz'],

            files: [],
            dataTransfer: null,

            // Файлы
            openModalFiles: false,
            openModalFilesType: 'file',
            selectedFiles: [],


            modalParentFolder: null,
            modalFolder: { title: null },
            slideModal: false,

            modalFile: { title: null },
            slideModalFile: false,

            userPermissions: {},

            modalPreviewFile: { title: null },
            slideModalPreviewFile: false,

            headerInfo: [
                { key: 'title', title: 'Название', order: null, availableOrder: true, },
                { key: 'updated_at', title: 'Дата', order: null, availableOrder: true, },
                { key: 'user_id', title: 'Пользователь', order: null, availableOrder: true, },
                { key: 'size', title: 'Размер', order: null, availableOrder: true, },
                { key: 'extension', title: 'Формат', order: null, availableOrder: true, },
                { key: 'actions', title: '#', order: null, availableOrder: false, },
            ],
        }
    },
    watch: {
        projectId(val) {
            this.syncFolder()
        }
    },
    computed: {
        dayjs() {
            return dayjs
        },
        availableCreate() {
            if (this.$store.getters.isAdmin()
                || this.$store.getters.checkPermission('project.edit')
                || this.$store.getters.checkPermission('project.create')) {
                return true
            }

            let permission = this.getUserFolderPermission(this.folder)
            return permission.write
        },
        acceptDocuments() {
            let array = []
            array.push(...this.word)
            array.push(...this.excel)
            array.push(...this.pdf)
            array.push(...this.txt)
            array.push(...this.image)
            array.push(...this.archive)
            //return '.' + array.join(',.')
            return '*'
        },
    },
    created() {
        let cookieSort = this.$cookies.get(this.$route.name + '_sortFileManager')
        if (cookieSort) {
            let sort = this.headerInfo.find(el => el.key === cookieSort.key)
            if (sort && sort.availableOrder && ['asc', 'desc'].includes(cookieSort.order)) {
                sort.order = cookieSort.order
            }
        }
    },
    mounted() {

    },
    methods: {
        openConfirmDeleteFileModal(item) {
            this.objectConfirmDeleteFile = item
            this.openConfirmDeleteFile = true
        },
        sortFolder(item) {
            if (!item.availableOrder) return

            let order = null
            if (!item.order) {
                order = 'asc'
            }
            if (item.order === 'asc') {
                order = 'desc'
            }
            item.order = order

            this.$cookies.set(this.$route.name + '_sortFileManager', item, 60 * 60 * 24 * 365)

            this.headerInfo.map(el => {
                if (el.key !== item.key) {
                    el.order = null
                }
            })

            this.syncFolder()
        },
        openModalSelectFile(type = 'file') {
            this.selectedFiles = []
            this.openModalFilesType = type
            this.openModalFiles = true
        },

        openModalWindowPreview(item) {
            this.modalPreviewFile = item
            this.slideModalPreviewFile = true
        },
        getUserFolderPermission(item) {
            if (!item.id) {
                return {
                    read: false,
                    write: false,
                }
            }

            if (this.userPermissions['folders'][item.id]) {
                return this.userPermissions['folders'][item.id]
            } else if (!item.parent_id) {
                return this.userPermissions['folders']['root']
            } else {
                // Ищем через родительскую папку
                let index = this.path.findIndex(el => el.id === item.parent_id)
                if (index !== -1) {
                    return this.getUserFolderPermission(this.path[index])
                }
            }
            return {
                read: false,
                write: false,
            }
        },
        getUserPermission(item) {
            if (this.$store.getters.isAdmin()
                || this.$store.getters.checkPermission('project.edit')
                || this.$store.getters.checkPermission('project.create')) {
                return {
                    read: true,
                    write: true,
                }
            }

            if (item.text_type === 'folder') {
                // Прямой доступ к папке
                return this.getUserFolderPermission(item)
            }

            if (item.text_type === 'file') {
                // Прямой доступ к файлу
                if (this.userPermissions['files'][item.id]) {
                    return this.userPermissions['files'][item.id]
                } else {
                    // Ищем через папку
                    return this.getUserFolderPermission(item)
                }
            }
            return {
                read: false,
                write: false,
            }
        },
        getWriteUserPermission(item) {
            let permission = this.getUserPermission(item)
            return permission.write
        },
        getReadUserPermission(item) {
            let permission = this.getUserPermission(item)
            return permission.read
        },

        openModalWindowItem(item) {
            if (item.text_type === 'folder') {
                return this.openModalWindowFolder(item)
            }
            if (item.text_type === 'file') {
                return this.openModalWindowFile(item)
            }
        },
        openModalWindowFile(item = null) {
            if (item) {
                let file = this.files.find(el => el.id === item.id)
                this.modalFile = file
            } else {
                this.modalFile = {
                    title: null
                }
            }
            this.slideModalFile = true
        },
        openModalWindowFolder(item = null) {
            if (item) {
                let folder = this.folders.find(el => el.id === item.id)
                this.modalFolder = folder
            } else {
                this.modalParentFolder = this.folder
                this.modalFolder = {
                    title: null
                }
            }
            this.slideModal = true
        },

        addFiles() {
            if (!this.selectedFiles.length) {
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: `Не выбрано ни одного файла`
                })
                return
            }

            let error = false
            let errors = []
            this.selectedFiles.map((file, index) => {
                let check = this.files.find(el => el.title === file.title)
                if (check) {
                    //console.log('df')
                    errors.push(file.title)
                    error = true
                }
            })

            if (error) {
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: `Файл(ы) с таким именем уже существуют: ${ errors.join(', ') }`
                })
                return
            }

            this.loading = true
            this.loadingText = null
            this.openModalFiles = false
            let data = new FormData();

            data.append('projectId', this.projectId)

            if (this.folder.id && this.folder.id !== 'root') {
                data.append('folderId', this.folder.id)
            }

            let dataFiles = {}
            this.selectedFiles.map((el, index) => {
                data.append(`file_${ index }`, el.file)
                dataFiles[`file_${ index }`] = el
                delete(dataFiles[`file_${ index }`].file)
            })

            data.append(`dataFiles`, JSON.stringify(dataFiles))

            axios
                .post(
                    this.$store.getters.getApiUrl('project.file'),
                    data,
                    {
                        onUploadProgress: progressEvent => {
                            //console.log(progressEvent)
                            let percent = Math.ceil((progressEvent.loaded / progressEvent.total) * 100)
                            if (percent === 100) {
                                this.loadingText = `Обработка файлов...`
                            } else {
                                this.loadingText = `Передача файлов ${ percent }%`
                            }
                            //console.log(this.loadingText)
                        }
                    }
                )
                .then(({ data }) => {
                    this.selectedFiles = []
                })
                .finally(() => {
                    this.syncFolder()
                    this.loading = false
                    this.loadingText = null
                })
        },

        deleteSelectedDocument(index) {
            this.selectedFiles.splice(index, 1)
        },

        // Событие при перетаскивании файла
        dragoverFile(event) {
            if (this.openModalFilesType === 'folder') {
                return
            }
            event.preventDefault();
            event.currentTarget.classList.add('over');
        },

        // Событие при убирании курсора при перетаскивании
        dragleaveFile(event) {
            if (this.openModalFilesType === 'folder') {
                return
            }
            event.currentTarget.classList.remove('over');
        },

        // Событие добавления файла
        dropFile(event, type) {
            if (this.openModalFilesType === 'folder') {
                return
            }

            // Снимаем стиль
            event.currentTarget.classList.remove('over')
            event.preventDefault()

            // Помещаем в input
            this.$refs.document.files = event.dataTransfer.files

            /*this.dataTransfer = new DataTransfer();

            var items = event.dataTransfer.items;
            for (var i = 0; i < items.length; i++) {
                // webkitGetAsEntry is where the magic happens
                var item = items[i].webkitGetAsEntry();
                if (item) {
                    this.traverseFileTree(item)
                }
            }

            //console.log(this.dataTransfer.files)

            this.$refs.document.files = this.dataTransfer.files

            console.log(this.$refs.document.files)*/

            this.onChangeFile(event, type)
        },

       /* traverseFileTree(item, path) {
            path = path || "";
            if (item.isFile) {
                //console.log(item);
                // Get file
                item.file((file) => {
                    this.dataTransfer.items.add(file);
                    //console.log(file)
                    //console.log("File:", path + file.name);
                });
            } else if (item.isDirectory) {
                // Get folder contents
                var dirReader = item.createReader();
                dirReader.readEntries((entries) => {
                    for (var i = 0; i < entries.length; i++) {
                        this.traverseFileTree(entries[i], path + item.name + "/");
                    }
                });
            }
        },*/

        // Изменение при добавлении файла
        onChangeFile(event, type) {
            let files = []
            files = this.$refs.document.files
            files = Array.from(files)

               //console.log(files)

            files.map(file => {
                let pathName = []
                if (file.webkitRelativePath) {
                    pathName = file.webkitRelativePath.split('/')
                    pathName.pop()
                }
                //console.log(pathName)
                this.selectedFiles.push({
                    file: file,
                    title: file.name,
                    pathName: pathName,
                    size: Math.ceil(file.size / 1024 / 1024) + ' Мб'
                })
            })
            this.$refs.document.value = null
        },

        openFile(item) {
            if (item.text_type === 'folder') {
                this.openSubFolder(item)
            } else {
                // Скачать файл
                let a = document.createElement('a');
                a.href = item.url;
                //a.target = "_blank";
                a.click();
            }
        },
        getIconByExtension(item) {
            if (item.text_type === 'folder') {
                return 'folder'
            }
            let extension = item.extension
            if (this.word.includes(extension)) {
                return 'word'
            }
            if (this.excel.includes(extension)) {
                return 'excel'
            }
            if (this.image.includes(extension)) {
                return 'image'
            }
            if (this.pdf.includes(extension)) {
                return 'pdf'
            }
            if (this.txt.includes(extension)) {
                return 'txt'
            }
            if (this.archive.includes(extension)) {
                return 'archive'
            }
            return 'other'
        },
        defaultPath() {
            return [
                {
                    id: 'root',
                    title: 'Корневая директория'
                }
            ]
        },
        defaultFolder() {
            return {}
        },
        syncFolder() {
            let sort = this.headerInfo.find(el => el.order !== null) ?? { key: null, order: null }

            this.loading = true
            if (this.folder?.id && this.folder.id !== 'root') {
                axios
                    .get(this.$store.getters.getApiUrl('project.folder', this.folder.id), {
                        params: {
                            sortBy: sort.key,
                            sortAs: sort.order
                        }
                    })
                    .then(({data}) => {
                        if (data.success) {
                            this.folder = data.folder
                            this.folders = data.folders
                            this.permissions = data.folder.permissions

                            //this.userPermissions = data.userPermissions

                            this.files = []
                            this.files.push(...this.folders)
                            this.files.push(...data.files)
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                axios
                    .get(this.$store.getters.getApiUrl('project.folder'), {
                        params: {
                            projectId: this.projectId,
                            sortBy: sort.key,
                            sortAs: sort.order
                        }
                    })
                    .then(({data}) => {
                        if (data.success) {
                            this.folder = data.folder
                            this.folders = data.folders

                            this.userPermissions = data.userPermissions

                            this.files = []
                            this.files.push(...this.folders)
                            this.files.push(...data.files)
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }

        },
        returnBack(item = null) {
            if (!item || item.id === 'root') {
                this.path = this.defaultPath()
                this.folder = this.defaultFolder()
                this.folders = []
                this.files = []
                this.parentFolder = null
            } else {
                let index = this.path.findIndex(el => item.id === el.id)
                this.path.splice(index, this.path.length - index)
                this.parentFolder = this.path[index - 1]
                this.folder = item
                this.folders = item.folders ?? []
                this.path.push(this.folder)
            }
            this.syncFolder()
        },
        openSubFolder(item = null) {
            this.parentFolder = JSON.parse(JSON.stringify(this.folder))
            this.files = []
            this.permissions = []
            // Создание
            if (!item) {
                this.folder = this.defaultFolder()
                this.permissions = []
            } else {
                this.folder = item
                this.folders = item.folders ?? []
                this.path.push(this.folder)
                this.syncFolder()
            }
        },
        deleteItem(item) {
            if (item.text_type === 'folder') {
                return this.deleteFolder(item)
            }
            if (item.text_type === 'file') {
                return this.deleteFile(item)
            }
        },
        deleteFolder(item) {
            this.loading = true
            axios
                .delete(this.$store.getters.getApiUrl('project.folder', item.id))
                .then(({ data }) => {
                    // Если текущая папка открыта
                    if (this.path[this.path.length - 1].id === item.id) {
                        this.returnBack(this.parentFolder)
                    } else {
                        // Если удаление из списка
                        this.syncFolder()
                    }
                })
                .finally(() => {
                    this.openConfirmDeleteFile = false
                    this.loading = false
                })
        },
        deleteFile(item) {
            this.loading = true
            axios
                .delete(this.$store.getters.getApiUrl('project.file', item.id))
                .then(({ data }) => {
                    this.selectedFiles = []
                })
                .finally(() => {
                    this.loading = false
                    this.openConfirmDeleteFile = false
                    this.syncFolder()
                })
        },

    }
}
</script>


<style scoped>

</style>
