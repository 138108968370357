require('./bootstrap');
window.Vue = require('vue').default;
window.axios = require('axios').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import 'es6-promise/auto';
import {store} from './store';
import {routes} from './router';
import VueRouter from "vue-router";

Vue.use(require('vue-cookies'))
Vue.use(VueRouter);


const router = new VueRouter({
    mode: 'history',
    //base: 'admin',
    routes
})


const token = Vue.$cookies.get('api_token')
if (token) {
    store.commit('setApiToken', token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 403) {
        store.commit('setNotification', {
            type: 'error',
            message: error.response.data.message
        })
        router.push({name: 'permission.denied'})
    }
    if (error.response.status === 401) {
        const originalRequest = error.config
        originalRequest._retry = true
        const data = error.response.data
        if (data.refresh) {
            const token = data.refresh
            store.commit('AUTH_LOGIN', token)
            originalRequest.headers.Authorization = 'Bearer ' + token
            console.log('>>> TOKEN REFRESH')
            return window.axios(originalRequest)
        } else {
            store.dispatch('AUTH_LOGOUT')
            router.push({name: 'auth.login'})
            console.log('>>> LOGOUT')
        }
    }
    return error;
});

// Пагинация страниц
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)


import { vMaska } from "maska"
Vue.directive("maska", vMaska)

// Языковые пакеты
import './ml'

// Формат даты
import {func} from './functions'
Vue.prototype.$func = func;

const app = new Vue({
    store,

    mounted() {

    },
    methods: {

    },

    router,
}).$mount('#app')
