export default {
    title_list: 'Страницы сайта',
    title_page: 'Страница сайта',
    title_create_page: 'Создать страницу сайта',
    title_edit_page: 'Изменить страницу сайта',

    labels: {

    },

    filter: {
        labels: {
            id: 'ID',
            title: 'Название на русском',
        }
    },

    table: {
        labels: {
            id: 'ID',
            category_id: 'Категория',
            visible: 'Отображается',
            slug: 'Ссылка',
            title: 'Русское название',
        }
    },

    buttons: {

    },

    form: {
        category_id: 'Категория',
        short_description: 'Краткое описание',
        description: 'Содержание',
        short_description_en: 'Краткое описание на английском',
        description_en: 'Содержание на английском',
        preview: 'Картинка превью',
        visible: 'Отображается',
        slug: 'Ссылка',
        title: 'Русское название',
        title_en: 'Английское название',

        meta_title: 'Заголовок для поисковых систем',
        meta_description: 'Описание для поисковых систем',
        meta_keywords: 'Ключевые слова для поисковых систем',

        meta_title_en: 'Заголовок для поисковых систем на английском',
        meta_description_en: 'Описание для поисковых систем на английском',
        meta_keywords_en: 'Ключевые слова для поисковых систем на английском',

        social_title: 'Заголовок для социальных сетей',
        social_description: 'Описание для социальных сетей',

        social_title_en: 'Заголовок для социальных сетей на английском',
        social_description_en: 'Описание для социальных сетей на английском',

        social_image: 'Изображение для социальных сетей',

    },
}
