<template>
    <div :class="['slideModal', {'open' : open}]">
        <div class="slideModalClose" @click="returnBack(null)"></div>
        <div class="modalContainer">
            <div class="head">
                <div class="title">
                    {{ titleModal }}
                </div>
            </div>

            <div class="body">
                <div class="back">
                    <div class="icon" @click="returnBack(null)"></div>
                </div>

                <div class="block">
                    <div class="title">Файл</div>
                    <div class="body">
                        <div class="formBlock">
                            <p :class="['label']">Название файла</p>
                            <input type="text"
                                   class="input"
                                   v-model="file.title"
                                   placeholder="Название файла">
                        </div>
                    </div>
                </div>

                <!-- Права -->
                <div class="block" v-if="file.id && availableEditPermission">
                    <div class="title">Права на файл</div>
                    <div class="body">
                        <div>
                            <button :class="['button', 'btn-primary']" @click="openModalUserFunction()">
                                Добавить пользователя
                            </button>
                        </div>
                        <div class="table-scroll__wrapper">
                            <table class="table" :key="permissionCounter">
                                <thead>
                                <tr>
                                    <th>Пользователь</th>
                                    <th>Чтение</th>
                                    <th>Запись</th>
                                    <th>#</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in permissions" >
                                    <td>{{ item.user.all_name }}</td>
                                    <td><input type="checkbox" v-model="item.read" @change="changePermission(item)"></td>
                                    <td><input type="checkbox" v-model="item.write" @change="changePermission(item)"></td>
                                    <td>
                                        <button class="button btn-danger" @click="openConfirmDeleteUser(item)">
                                            <span class="icon ico-i icon-delete"></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="!permissions.length">
                                    <td colspan="4">Нет ни одного пользователя</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="bottom">
                <button class="button btn-success" @click="saveFile">
                    Сохранить
                </button>
                <a :href="file.url" class="button btn-primary">
                    Скачать
                </a>
                <button class="button btn-danger" @click="deleteFile(file)" v-if="file.id">
                    Удалить
                </button>
            </div>

            <div :class="['loadingModal', {'active' : loading}]" ref="loadWindow">
                <div>
                    <img src="/images/dual_loader.gif">
                </div>
            </div>
        </div>

        <select-users @selected="selectedUsers"
                      :modal-open="openModalUser"
                      @close="openModalUser = false">
        </select-users>

        <ModalWindow :open="openModalConfirmDeleteUser"
                     @closeModal="openModalConfirmDeleteUser = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                <template v-if="confirmDeleteUser">
                    Вы действительно хотите удалить разрешения для пользователя "{{ confirmDeleteUser.user.short_name }}"?
                </template>
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deletePermission(confirmDeleteUser)">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

    </div>
</template>

<script>
import ModalWindow from "../../Utils/ModalWindow.vue";
import Multiselect from "vue-multiselect";
import SelectUsers from "./SelectUsers.vue";
export default {
    name: "SelectFile",
    components: {
        SelectUsers,
        ModalWindow,
        Multiselect
    },
    props: {
        modalFile: {
            type: Object,
            default: () => { return this.defaultFolder() }
        },
        modalOpen: {
            type: Boolean,
            default: false
        },
        projectId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            file: this.modalFile,
            open: this.modalOpen,
            loading: false,

            permissionCounter: 0,
            openModalUser: false,
            selectUsers: [],
            selectUser: null,
            permissions: [],

            openModalConfirmDeleteUser: false,
            confirmDeleteUser: null,
        }
    },
    watch: {
        modalFile(val) {
            this.file = val
            this.syncFile()
        },
        modalOpen(val) {
            this.open = val
        }
    },
    computed: {
        availableEditPermission() {
            if (this.$store.getters.isAdmin()
                || this.$store.getters.checkPermission('project.edit')
                || this.$store.getters.checkPermission('project.create')) {
                return true
            }
            return false
        },
        titleModal() {
            if (this.file && this.file.id) {
                return `Изменить файл "${ this.file.title }" (ID ${ this.file.id })`
            }
            return 'Добавить файл'
        }
    },
    methods: {
        openConfirmDeleteUser(item) {
            this.confirmDeleteUser = item
            this.openModalConfirmDeleteUser = true
        },
        selectedUsers(val) {
            val.map(el => {
                this.addUser(el)
            })
        },
        deletePermission(item) {
            let index = this.permissions.findIndex(el => el.user_id === item.user_id)
            if (index !== -1) {
                this.permissions.splice(index, 1)
            }
            this.openModalConfirmDeleteUser = false
        },
        changePermission(item) {
            if (item.write) {
                item.read = true
            }
            this.permissionCounter++
        },
        addUser(user) {
            let find = this.permissions.find(el => el.user_id === user.id)
            if (find) {
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: `Пользователь ${ user.short_name } уже присутствует`
                })
            } else {
                this.permissions.push({
                    file_id: this.file.id,
                    user_id: user.id,
                    user: user,
                    read: true,
                    write: false,
                })
            }
            this.openModalUser = false
        },
        openModalUserFunction() {
            this.loading = true
            axios
                .get(this.$store.getters.getApiUrl('project.users'))
                .then(({ data }) => {
                    this.selectUsers = data.users
                    this.openModalUser = true
                })
                .finally(() => {
                    this.loading = false
                })
        },

        returnBack(item = null) {
            this.open = false
            this.file = null
            this.permissions = []
            this.$emit('close')
        },
        syncFile() {
            this.loading = true
            axios
                .get(this.$store.getters.getApiUrl('project.file', this.file.id))
                .then(({data}) => {
                    if (data.success) {
                        this.file = data.file
                        this.permissions = data.file.permissions
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        saveFile() {
            this.loading = true
            axios
                .put(this.$store.getters.getApiUrl('project.file', this.file.id), {
                    file: this.file,
                    project_id: this.projectId,
                    permissions: this.permissions
                })
                .then(({ data }) => {
                    this.permissions = data.file.permissions
                    this.$emit('updateRootFolders')
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteFile(item) {
            this.loading = true
            axios
                .delete(this.$store.getters.getApiUrl('project.file', item.id))
                .then(({ data }) => {
                    this.returnBack(null)
                    this.$emit('updateRootFolders')
                })
                .finally(() => {
                    this.loading = false
                })
        },

    }
}
</script>


<style scoped>

</style>
