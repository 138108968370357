export const func = {
    getDateTime(date) {
        if (!date) {
            return null
        }
        let parse = new Date(Date.parse(date))
        return parse.toLocaleString("ru-RU")
    },
    getDate(date) {
        if (!date) {
            return null
        }
        let parse = new Date(Date.parse(date))
        return parse.toLocaleDateString('ru-Ru')
    },
}
