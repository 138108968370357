<template>
    <div>
        <div :class="['left', {'open': openMenu}]">
            <div class="wrapper-mobile" @click="openMenu = !openMenu"></div>
            <div class="menu">
                <ul>
                    <template v-for="item in menu">
                        <li v-if="(item.permission && $store.getters.checkPermission(item.permission))
                                || !item.permission">
                            <template v-if="item.link !== 'site'">
                                <router-link :to="{ name: item.link }">
                                    <span :class="['icon', 'ico-mi', 'icon-'+item.icon]"></span>
                                    <span class="label">{{ item.title }}</span>
                                </router-link>
                            </template>
                            <template v-else>
                                <a href="/public" target="_blank">
                                    <span :class="['icon', 'ico-mi', 'icon-'+item.icon]"></span>
                                    <span class="label">{{ item.title }}</span>
                                </a>
                            </template>

                            <div class="submenu" v-if="item.children.length > 0">
                                <p class="submenu__label">{{ item.submenuTitle }}</p>
                                <ul>
                                    <li v-if="(itemSubmenu.permission &&
                                            $store.getters.checkPermission(itemSubmenu.permission))
                                            || !itemSubmenu.permission"
                                        v-for="itemSubmenu in item.children"
                                        @click="openMenu = !openMenu">
                                        <router-link :to="{ name: itemSubmenu.link }">
                                            {{ itemSubmenu.title }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </template>

                    <li class="mobile-button">
                        <router-link :to="{ name: null }">
                            <span :class="['icon', 'ico-mi', 'icon-settings']"></span>
                            <span class="label">Настройки</span>
                        </router-link>
                        <div class="submenu">
                            <p class="submenu__label">
                                {{ $store.state.user.surname }} {{ $store.state.user.name }}
                            </p>
                            <ul>
                                <li>
                                    <a href="#" @click="exitUser">
                                        Выйти
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="right">
            <div class="top">
                <div class="title">
                    {{ $store.state.dashboardTitle }}
                </div>

                <div class="user">
                    <div class="currencies">
                        <div class="item" v-for="(item, index) in $store.state.currencies">
                            <span class="label">{{ item.currency.title }}:</span>
                            <span>{{ item.value }}</span>
                        </div>
                    </div>
                    <div class="profile">
                        {{ $store.state.user.surname }} {{ $store.state.user.name }}
                    </div>
                    <span class="exit" @click="exitUser">
                        Выйти
                    </span>
                </div>

                <div class="mobile-menu">
                    <span class="icon ico-mi icon-menu" @click="openMenu = !openMenu"></span>
                </div>
            </div>

            <div class="content">
                <router-view></router-view>
            </div>
        </div>


        <Notifications></Notifications>
        <ErrorModalWindow></ErrorModalWindow>
        <SuccessModalWindow></SuccessModalWindow>
        <LoadingModal></LoadingModal>

    </div>
</template>

<script>
import SuccessModalWindow from "./Utils/SuccessModalWindow.vue";
import ErrorModalWindow from "./Utils/ErrorModalWindow.vue";
import LoadingModal from "./Utils/LoadingModal.vue";
import Notifications from "./Utils/Notifications.vue";

export default {
    name: "Layout",
    components: {
        LoadingModal,
        ErrorModalWindow,
        SuccessModalWindow,
        Notifications,
    },

    data() {
        return {
            userPermission: null,
            permissions: {
                1: 'user',
                3: 'admin',
            },

            openMenu: false,
            menu: [
                /*{
                    title: 'Перейти на сайт',
                    icon: 'site-home',
                    link: 'site',
                    children: []
                },*/
                {
                    title: 'AGS Админ',
                    icon: 'home',
                    link: 'dashboard',
                    children: []
                },
                /*{
                    title: "Сайт",
                    icon: 'site',
                    link: null,
                    permission: 'site',
                    submenuTitle: "Управление сайтом",
                    children: [
                        { title: 'Меню', link: 'menu', permission: 'site.menu' },
                        { title: 'Страницы', link: 'page', permission: 'site.page' },
                        { title: 'Категории страниц', link: 'category', permission: 'site.category' },
                        { title: 'Настройки', link: 'settings', permission: 'site.settings' },
                    ]
                },*/

                {
                    title: "Пользователи",
                    icon: 'user',
                    link: null,
                    permission: 'user',
                    submenuTitle: "Пользователи",
                    children: [
                        { title: 'Пользователи', link: 'user', permission: 'user' },
                        { title: 'Должности', link: 'position', permission: 'user.position' },
                        { title: 'Договора', link: 'contract', permission: 'user.contract' },
                        { title: 'Платежи', link: 'user.contract.payment', permission: 'user.contract.payment' },
                        { title: 'Роли пользователей', link: 'role', permission: 'user.role' },
                        { title: 'Права пользователей', link: 'permission', permission: 'user.permission' },
                    ]
                },

                {
                    title: "Проекты",
                    icon: 'project',
                    link: null,
                    permission: 'project',
                    submenuTitle: "Управление проектами",
                    children: [
                        { title: 'Проекты', link: 'project', permission: 'project' },
                        { title: 'Договора', link: 'project.contract', permission: 'project.contract' },
                        { title: 'Статусы', link: 'project.status', permission: 'project.status' },
                    ]
                },
            ]
        }
    },
    created() {
        this.getUser()
    },
    mounted() {},
    methods: {
        getUser() {
            axios.get(this.$store.getters.getApiUrl('dashboard'))
                .then(({data}) => {
                    if (data.success) {
                        this.$store.commit('setUser', data.user)
                        this.$store.commit('setPermissions', data.permissions)
                        this.$store.commit('setRoles', data.roles)
                        //this.$store.commit('setCurrency', data.currencies)
                    }
                })
        },
        exitUser(){
            this.$store.dispatch('AUTH_LOGOUT')
            this.$router.push({name:'auth.login'})
        }
    }
}
</script>

<style scoped>

</style>
