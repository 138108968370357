import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import Russian from './lang/ru'

Vue.use(MLInstaller)

export default new MLCreate({
    initial: 'ru',
    save: true,
    languages: [
        new MLanguage('ru').create(Russian)
    ]
})
