<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.contract'),

            filter: {
                user_id: { value: null, type: 'text'},
                number: { value: null, type: 'text'},
            },

            tableHeader: [
                { key: 'id', is_sort: true },
                { key: 'user_id', is_sort: true, classes: 'text-l' },
                { key: 'number', is_sort: true, classes: 'text-l' },
                { key: 'end_date', is_sort: true, classes: 'text-l' },
            ],

            tableBody: {
                user_id: { type: 'function', function: this.getUserName },
                end_date: { type: 'function', function: this.getEndDate },
            },

            apiList: this.$store.getters.getApiUrl('contract'),
            pageRoute: 'contract',
            pageRouteCreate: 'contract.create',
            pageRouteEdit: 'contract.edit',

            pagePermission: 'user.contract',
        }
    },
    methods: {
        getUserName(item) {
            return item.user?.all_name
        },
        getEndDate(item) {
            if (item.is_unlimited) {
                return 'Бессрочный'
            }
            return this.$func.getDate(item.end_date)
        },
    }
}
</script>

<style scoped>

</style>
