<template>
    <div class="notifications">

        <div :class="['notify', 'notify__'+item.type]" v-for="item in list">
            <div class="title">
                {{ title[item.type] }}
                <span class="close" @click="closeNotify(item.id)">x</span>
            </div>
            <div class="body">{{ item.message }}</div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Notifications",
    data() {
        return {
            title: {
                error: this.$ml.get('interface.error'),
                success: this.$ml.get('interface.success'),
            },
            list: [
                //{ message: 'Сообщение', type: 'error', id: 1},
                //{ message: 'Сообщение', type: 'success', id: 2}
            ],
        }
    },
    methods: {
        openNotify(item) {
            this.list.push(item)
            if (item.auto && item.auto !== false) {
                return
            }
            setTimeout(() => {
                this.closeNotify(item.id)
            }, 3000)
        },
        closeNotify(id) {
            let index = this.list.findIndex(item => item.id === id)
            if (index !== -1) {
                this.list.splice(index, 1)
            }
        },
    },
    computed: {
        messages() {
            return this.$store.getters.getNotification
        }
    },
    watch: {
        messages (newVal, oldVal) {
            if (newVal !== null) {
                this.openNotify(newVal)
            }
        }
    }
}
</script>

<style scoped>

</style>
