<template>
    <div class="default-page">

        <div class="buttons">
            <button class="button btn-primary" @click="saveForm">
                {{ $ml.get('buttons.save') }}
            </button>

            <button class="button btn-primary" @click="goToList">
                {{ $ml.get('buttons.back') }}
            </button>

            <button class="button btn-danger"
                    v-if="this.element && this.element.id"
                    @click="openConfirmDelete = true">
                {{ $ml.get('buttons.delete') }}
            </button>

            <button v-for="(button, index) in buttons"
                    :class="['button', button.class]"
                    @click="button.function">
                {{ button.label }}
            </button>
        </div>

        <div class="block" :key="change">
            <div class="title">{{ pageTitle }}</div>
            <div class="body flex-row-wrap">
                <div :class="getClass(key)"
                     v-for="(label, key) in labels">
                    <div class="formBlock">
                        <p :class="['label', {'required' : form_required.includes(label)}]">
                            {{ form_labels[label] }}
                            <template v-if="form_required.includes(label)">*</template>
                        </p>
                        <template v-if="getTypeForm(label) === 'number'">
                            <input type="number"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>

                        <template v-if="getTypeForm(label) === 'string'">
                            <div class="input disabled" v-if="element[label]">{{ element[label] }}</div>
                        </template>
                        <template v-if="getTypeForm(label) === 'date'">
                            <date-picker
                                lang="ru"
                                valueType="format"
                                format="DD.MM.YYYY"
                                v-model="element[label]"
                                :placeholder="form_labels[label]"
                            />
                        </template>
                        <template v-if="getTypeForm(label) === 'link'">
                            <textarea
                                class="textarea"
                                v-model="element[label]"
                                :placeholder="form_labels[label]">
                            </textarea>
                        </template>
                        <template v-if="getTypeForm(label) === 'textarea'">
                            <textarea
                                class="textarea"
                                v-model="element[label]"
                                :placeholder="form_labels[label]">
                            </textarea>
                        </template>
                        <template v-if="getTypeForm(label) === 'input'">
                            <input type="text"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>
                        <template v-if="getTypeForm(label) === 'select'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    :label="getSelectLabel(label)"
                                    v-model="element[label]"
                                    :options="dictionaries[label]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'boolean'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    label="title"
                                    v-model="element[label]"
                                    :options="selectBooleanOptions"
                                    :placeholder="form_labels[label]">
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'custom-select'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    :label="typeForm[label].label"
                                    :value="element[label]"
                                    v-model="element[label]"
                                    :options="dictionaries[typeForm[label].dictionary]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'select-tags'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    label="title"
                                    :multiple="true"
                                    :taggable="true"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :value="element[label]"
                                    v-model="element[label]"
                                    :options="dictionaries[label]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="title">Права</div>
            <div class="body">
                <treeselect v-model="permissionsSelected"
                            value-consists-of="ALL_WITH_INDETERMINATE"
                            sort-value-by="INDEX"
                            openDirection="bottom"
                            placeholder="Выбрать..."
                            :multiple="true"
                            :options="permissions" />
            </div>
        </div>

        <ModalWindow :open="openConfirmDelete"
                     @closeModal="openConfirmDelete = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                {{ $ml.get('interface.confirm_action_delete') }}
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteForm">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

    </div>
</template>

<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: "Form",
    mixins: [FormDefault],
    components: {
        Treeselect
    },
    data() {
        return {
            lang: this.$ml.get('pages.role'),
            buttons: [
            ],

            typeForm: {

            },

            pageRouteList: 'role',
            pageRouteEdit: 'role.edit',
            pageApi: 'role',
            pagePermission: 'user.role',

            permissions: [],
            permissionsSelected: [],
        }
    },
    methods: {
        setExtraDataCreate() {
            axios.get(this.$store.getters.getApiUrl('user'), {
                params: {
                    type: 'getRolesPermissions',
                }
            }).then((response) => {
                let data = response.data
                this.permissions = data.permissions
            })
        },
        setExtraData(data) {
            this.permissions = data.permissions
            this.permissionsSelected = data.permissionsSelected
        },
        appendDataSave() {
            return {
                permissions_selected: this.permissionsSelected
            }
        },
    }
}
</script>

<style scoped>

</style>
