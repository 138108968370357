export default {
    title_list: 'Разрешения администраторов',
    title_page: 'Разрешение администратора',
    title_create_page: 'Создать разрешение администратора',
    title_edit_page: 'Изменить разрешение администратора',

    filter: {
        labels: {
            slug: 'Ключ',
            title: 'Название',
            parent_id: 'Родительское разрешение',
        }
    },

    table: {
        labels: {
            id: 'ID',
            slug: 'Ключ',
            title: 'Название',
            parent_id: 'Родительское разрешение',
        }
    },

    buttons: {

    },

    form: {
        slug: 'Ключ',
        title: 'Название',
        parent_id: 'Родительское разрешение',
    },
}
