import User from "./user/_user";
import Role from "./user/_role";
import Permission from "./user/_permission";
import Position from "./user/_position";
import Contract from "./user/_contract";
import payment from "./user/_payment";

import settings from "./site/_settings";
import menu from "./site/_menu";
import page from "./site/_page";
import category from "./site/_category";

import project from "./project/_project";
import projectStatus from "./project/_projectStatus";
import projectContract from "./project/_projectContract";

export default {
    user: User,
    role: Role,
    permission: Permission,
    position: Position,
    contract: Contract,
    payment: payment,

    settings: settings,
    menu: menu,
    page: page,
    category: category,

    project: project,
    projectStatus: projectStatus,
    projectContract: projectContract,
}
