<template>
    <div class="authPage">

        <div class="title">
            {{ $store.state.systemTitle }}
        </div>

        <div class="loginForm">
            <div class="formBlock" v-for="(label, key) in labels">
                <label>
                    <p class="label">{{ label }}</p>
                    <input class="input" type="text" v-model="user[key]" :placeholder="label">
                </label>
            </div>
            <div class="formBlock">
                <button class="button btn-primary" @click="enter">Зарегистрироваться</button>
            </div>

            <div class="formBlock">
                <p class="errorNotify" v-if="showError">{{ errorNotify }}</p>
                <p class="successNotify" v-if="showSuccess">{{ successNotify }}</p>
            </div>

        </div>
        <div class="links">
            <router-link :to="{name: 'auth.login'}">Войти</router-link>
            <router-link :to="{name: 'auth.forgot_password'}">Забыли пароль?</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
    data() {
        return {
            user: {
                surname: '',
                name: '',
                patronymic: '',
                phone: '',
                email: '',
                password: '',
                password_confirm: '',
            },
            labels: {
                surname: 'Фамилия',
                name: 'Имя',
                patronymic: 'Отчество',
                phone: 'Телефон',
                email: 'Email',
                password: 'Пароль',
                password_confirm: 'Повторите пароль',
            },
            type: 'Register',
            showError: false,
            errorNotify: null,
            showSuccess: false,
            successNotify: null,
        }
    },
    methods: {
        enter() {
            this.errorNotify = null
            this.successNotify = 'Регистрируем...'
            axios.post('/api/auth', {
                type: this.type,
                user: this.user
            })
            .then(({data}) => {
                if (data.success) {
                    this.successNotify = data.message
                    //this.$store.dispatch('AUTH_LOGIN', data.token)
                    //this.$router.push({ name: 'dashboard' })
                } else {
                    this.successNotify = null
                    this.errorNotify =  data.message
                }
            })
            .catch((error) => {
                this.successNotify = null
                this.errorNotify = 'Произошла ошибка'
            })
        }
    },
    watch: {
        errorNotify(val) {
            if (val !== null) {
                this.showError = true
            } else {
                this.showError = false
            }
            this.errorNotify = val
        },
        successNotify(val) {
            if (val !== null) {
                this.showSuccess = true
            } else {
                this.showSuccess = false
            }
            this.successNotify = val
        }
    }
}
</script>

<style scoped>

</style>
