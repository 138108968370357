export default {
    title_list: 'Договора сотрудников',
    title_page: 'Договор сотрудника',
    title_create_page: 'Создать договор сотрудника',
    title_edit_page: 'Изменить договор сотрудника',

    filter: {
        labels: {
            user_id: 'Сотрудник',
            number: 'Номер договора',
        }
    },

    table: {
        labels: {
            id: 'ID',
            user_id: 'Сотрудник',
            number: 'Номер договора',
            end_date: 'Дата окончания',
        }
    },

    buttons: {

    },

    form: {
        user_id: 'Сотрудник',
        number: 'Номер договора',
        number_object: 'Номер объекта',
        end_date: 'Дата окончания',
        active: 'Действителен',
        note: 'Примечание',

        is_unlimited: 'Бессрочный',
        amount: 'Сумма договора',
    },
}
