<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";

export default {
    name: "Form",
    mixins: [FormDefault],
    data() {
        return {
            lang: this.$ml.get('pages.projectStatus'),
            buttons: [
            ],

            typeForm: {
                //user_id: 'select',
            },

            pageRouteList: 'project.status',
            pageRouteEdit: 'project.status.edit',
            pageApi: 'project.status',
            pagePermission: 'project.status',
        }
    },
    methods: {
        getSelectLabel(label) {
            return 'title'
        },
    }
}
</script>

<style scoped>

</style>
