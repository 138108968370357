<template>
    <div class="default-page">
        <div class="buttons">
            <button class="button btn-primary"
                    v-if="getPermissionSection('create') && !viewOnly && isPageList"
                    @click="goToCreate">
                {{ $ml.get('buttons.create') }}
            </button>

            <button class="button btn-primary"
                    v-if="!isPageList"
                    @click="sendSelected">
                Выбрать отмеченных
            </button>



            <button :class="['button', button.class]" :key="index"
                    v-for="(button, index) in buttons"
                    @click="button.function">
                {{ button.label }}
            </button>
        </div>

        <TableFilter
            v-if="getPermissionSection('view_search')"
            @resetFilter="resetFilter"
            @applyFilter="applyFilter"
            :filter="filter"
            :filter-open="filterOpen"/>

        <div class="block" v-if="getPermissionSection('view_list')">
            <div class="title">{{ dashboardTitle }}</div>
            <div class="body">
                <Table :paginate-count="paginateCount"
                       :page="page"
                       :last_page="last_page"
                       @setPage="setPage">
                    <template slot="thead">
                        <Thead
                               :is-checked-all="!isPageList"
                               :header="tableHeader"
                               :is-counter="tableHeaderChange"
                               @checkedAll="checkedAll"
                               @sortBy="sortBy" />
                    </template>
                    <template slot="tbody">
                        <tr v-for="item in listItems" :key="item.id" :class="getClassRow(item)" :style="getStyleRow(item)">
                            <td>
                                <template v-if="isPageList">
                                    <a class="link"
                                       v-if="getPermissionSection('edit')"
                                       :href="getLinkEdit(item.id)">
                                        {{ item.id }}
                                    </a>
                                    <span v-else>{{ item.id }}</span>
                                </template>
                                <template v-else>
                                    <input type="checkbox" v-model="selected" :value="item">
                                </template>
                            </td>
                            <td v-for="(col, index) in tableHeader"
                                v-if="col.key !== 'id'"
                                :key="index"
                                :class="[{'text-left': col.key === 'title'}, col.classes ? col.classes : '']">

                                <div :class="col.dataClasses ?? ''" >
                                    <template v-if="tableBody[col.key]">
                                        <template v-if="tableBody[col.key].type === 'boolean'">
                                            {{ getBooleanValue(item[col.key]) }}
                                        </template>
                                        <template v-if="tableBody[col.key].type === 'dictionary'">
                                            {{ getDictionaryValue(tableBody[col.key], item) }}
                                        </template>
                                        <template v-if="tableBody[col.key].type === 'concat'">
                                            {{ getConcatValue(tableBody[col.key], item) }}
                                        </template>
                                        <template v-if="tableBody[col.key].type === 'function'">
                                            <div v-html="tableBody[col.key].function(item)"></div>
                                        </template>
                                    </template>
                                    <template v-else-if="col.key === 'activated'">
                                        <select class="select"
                                                @change="saveItem(item)"
                                                v-model="item.activated">
                                            <option v-for="item in filtersDefault.activated.options"
                                                    :value="item.id">
                                                {{ item.title }}
                                            </option>
                                        </select>
                                    </template>
                                    <template v-else-if="col.key === 'buttons' && !viewOnly">
                                        <a class="button btn-primary"
                                           v-if="getPermissionSection('edit')"
                                           :title="$ml.get('interface.edit')"
                                           :href="getLinkEdit(item.id)">
                                            <span class="icon ico-i icon-edit"></span>
                                        </a>
                                        <a class="button btn-primary"
                                           v-if="!getPermissionSection('edit') && getPermissionSection('view_list')"
                                           :title="$ml.get('interface.view')"
                                           :href="getLinkEdit(item.id)">
                                            <span class="icon ico-i icon-view"></span>
                                        </a>
                                        <button class="button btn-danger"
                                                v-if="getPermissionSection('delete')"
                                                :title="$ml.get('interface.delete')"
                                                @click="openDeleteWindow(item)">
                                            <span class="icon ico-i icon-delete"></span>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <span :title="item[col.key]">{{ item[col.key] }}</span>
                                    </template>
                                </div>

                            </td>
                        </tr>
                    </template>
                </Table>
            </div>
        </div>

        <ModalWindow :open="openConfirmDelete"
                     @closeModal="openConfirmDelete = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                {{ $ml.get('interface.confirm_action_delete') }}
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteItem">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

    </div>
</template>

<script>
import TableList from "../Table/TableList.vue";
import ModalWindow from "../ModalWindow.vue";
import th from "vue2-datepicker/locale/es/th";

export default {
    name: "ListDefault",
    mixins: [TableList],
    components: {
        ModalWindow,
    },
    props: {
        isPageList: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            openConfirmDelete: false,
            confirmDeleteItem: null,

            viewOnly: false,

            selected: [],

            buttons: [],
            lang: {},

            filter: {},
            filtersDefault: {
                activated: { value:null, type:'select',
                    options: [
                        { id: null, title: this.$ml.get('interface.no') },
                        { id: true, title: this.$ml.get('interface.yes') },
                    ]
                },
            },

            tableHeader: [],
            tableHeaderAppend: [
                { label: '#', key: 'buttons', is_sort: false },
            ],
            tableBody: {},
            dictionaries: {},

            apiList: '',
            dashboardTitle: null,
            pageRoute: '',
            pageRouteCreate: '',
            pageRouteEdit: '',
            pagePermission: '',

            selectBooleanOptions: [
                { id: null, title: this.$ml.get('interface.no') },
                { id: false, title: this.$ml.get('interface.no') },
                { id: true, title: this.$ml.get('interface.yes') },
            ],


        }
    },
    created() {
        this.setLang()
        this.setDashboardTitle()
        this.setFilters()
        this.getData()

        if (!this.viewOnly) {
            this.tableHeader.push(...this.tableHeaderAppend)
        }
    },
    mounted() {

    },
    methods: {
        sendSelected() {
            this.$emit('selected', this.selected)
        },
        checkedAll(val) {
            if (val) {
                this.selected = this.listItems
            } else {
                this.selected = []
            }
        },
        getStyleRow(item) { return '' },
        getClassRow(item) { return '' },

        getPermissionSection(key) {
            return this.$store.getters.checkPermission(this.pagePermission + '.' + key)
        },

        saveItem(item) {
            this.$store.commit('setLoading', true)
            return axios.put(this.$store.getters.getApiUrl(this.pageRoute, item.id), {
                element: item
            })
            .then(({data}) => {
                if (data.success) {
                    this.$store.commit('setNotification', {
                        type: 'success',
                        message: this.$ml.get('interface.saved')
                    })
                } else {
                    this.$store.commit('setErrorMessage', data.message)
                }
            }).catch((error) => {
                console.log(error)
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: 'Ошибка сервера. Обратитесь к администратору.'
                })
            })
            .finally(() => {
                this.$store.commit('setLoading', false)
            })
        },

        getBooleanValue(value) {
            switch (value) {
                case 0:
                    value = false
                    break;
                case 1:
                    value = true
                    break;
            }

            let index = this.selectBooleanOptions.findIndex(item => item.id === value)
            if (index !== -1) {
                return this.selectBooleanOptions[index].title
            }
            return null
        },

        openDeleteWindow(item) {
            this.openConfirmDelete = true
            this.confirmDeleteItem = item
        },
        deleteItem() {
            this.$store.commit('setLoading', true)
            axios.delete(this.apiList + '/' + this.confirmDeleteItem.id)
                .then(({data}) => {
                    if (data.success) {
                        let index = this.listItems.findIndex(element => element.id === this.confirmDeleteItem.id)
                        if (index !== -1) {
                            this.listItems.splice(index, 1)
                        }
                        this.$store.commit('setNotification', {
                            type:'success',
                            message: this.$ml.get('interface.deleted')
                        })
                    }
                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                    this.openConfirmDelete = false
                })
        },
        getConcatValue(col, element) {
            let separator = ' '
            let implode = []
            if (col.separator) {
                separator = col.separator
            }
            col.keys.map(key => {
                let keys = key.split('.')
                let value = element
                let append = false
                keys.map(key => {
                    if (value[key]) {
                        append = true
                        value = value[key]
                    }
                })
                if (append) {
                    implode.push(value)
                }
            })
            return implode.join(separator)
        },
        getDictionaryValue(col, element) {
            let dictionary = this.dictionaries[col.dictionary]

            if (!dictionary) return null

            let index = dictionary.findIndex(item => item[col.index] === element[col.local_index])
            if (index !== -1) {
                return dictionary[index][col.label]
            }
            return null
        },
        setLang () {
            if (!this.dashboardTitle) {
                this.dashboardTitle = this.lang.title_list
            }

            for (let key in this.filter) {
                this.filter[key].label = this.lang.filter.labels[key]
            }

            this.tableHeader.map(item => {
                item.label = this.lang.table.labels[item.key]
                return item
            })
            this.buttons.map(item => {
                item.label = this.lang.buttons[item.key]
                return item
            })
        }
    }
}
</script>

<style scoped>

</style>
