<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.permission'),

            filter: {
                slug: { value: null, type: 'text'},
                title: { value: null, type: 'text'},
            },
            tableHeader: [
                //{ label: '#', is_sort: false },
                { key: 'id', is_sort: true },
                { key: 'title', is_sort: true, classes: 'text-l' },
                { key: 'slug', is_sort: true, classes: 'text-l' },
            ],

            apiList: this.$store.getters.getApiUrl('permission'),
            pageRoute: 'permission',
            pageRouteCreate: 'permission.create',
            pageRouteEdit: 'permission.edit',

            pagePermission: 'user.permission',
        }
    },
}
</script>

<style scoped>

</style>
