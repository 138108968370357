<template>
    <div>
        <div class="toolbar">
            <paginate v-if="last_page !== 1"
                v-model="tablePage"
                :page-count="last_page"
                :click-handler="setPage"
                :prev-text="$ml.get('table.paginate.prev')"
                :next-text="$ml.get('table.paginate.next')"
                :container-class="'paginate'">
            </paginate>
            <div v-else></div>

            <div class="w20">
                <select class="select" v-model="paginate">
                    <option v-for="option in paginateCounts"
                            :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>


        <div class="table-scroll__wrapper">
            <table class="table-scroll">
                    <slot name="thead"></slot>
                <tbody :key="counter">
                    <slot name="tbody"></slot>
                </tbody>
            </table>
        </div>
        <paginate v-if="last_page !== 1"
            v-model="tablePage"
            :page-count="last_page"
            :click-handler="setPage"
            :prev-text="$ml.get('table.paginate.prev')"
            :next-text="$ml.get('table.paginate.next')"
            :container-class="'paginate'">
        </paginate>
    </div>
</template>

<script>
export default {
    name: "Table",
    props: {
        type: { type: String, default: null },
        page: { type: Number, default: 1},
        last_page: { type: Number, default: 1},
        counter: { type: Number, default: 1},
        paginateCount: { type: Number, default: 20},
    },
    computed: {
        tablePage: {
            set() { },
            get() { return this.page }
        }
    },
    watch: {
        paginateCount(val) {
            this.paginate = val
        },
        paginate(val) {
            this.$emit('setPage', this.page, val)
        }
    },
    data() {
        return {
            paginate: this.paginateCount,
            paginateCounts: [ 20, 50, 100, 200 ],
        }
    },
    methods: {
        setPage(page) {
            this.$emit('setPage', page)
        }
    }
}
</script>

<style scoped>

</style>
