<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.user'),

            filter: {
                all_name: { value: null, type: 'text'},
                type_labor_relation_id: { value: null, type: 'select', options: []},
                position_id: { value: null, type: 'select', options: []},
            },

            tableHeader: [
                //{ label: '#', is_sort: false },
                { key: 'id', is_sort: true },
                { key: 'all_name', is_sort: true, classes: 'text-l' },
                { key: 'email', is_sort: true, classes: 'text-l' },
                { key: 'phone', is_sort: true, classes: 'text-l' },
                { key: 'contract', is_sort: true, classes: 'text-l' },
                { key: 'position', is_sort: true, classes: 'text-l' },
                { key: 'labor_relation', is_sort: true, classes: 'text-l' },
            ],

            tableBody: {
                contract: { type: 'function', function: this.getContractNumber },
                position: { type: 'function', function: this.getPositionName },
                labor_relation: { type: 'function', function: this.getLaborRelation },
            },

            apiList: this.$store.getters.getApiUrl('user'),
            pageRoute: 'user',
            pageRouteCreate: 'user.create',
            pageRouteEdit: 'user.edit',
            pagePermission: 'user',
        }
    },
    mounted() {
        this.getDictionaries().then(({data}) => {
            this.filter.position_id.options = data.dictionaries.position_id
            this.filter.type_labor_relation_id.options = data.dictionaries.type_labor_relation_id
        })
    },
    methods: {
        getStyleRow(item) {
            if (item.contract_status === 'canceled') {
                return 'background: #fecaca;';
            }
            if (item.contract_status === 'waiting') {
                return 'background: #fef3c7;';
            }
            return ''
        },
        getContractNumber(item) {
            if (item.active_contract) {
                let link = this.$router.resolve({ name: 'contract.edit', params: {id: item.active_contract.id}})

                let text = [
                    item.active_contract.number,
                    item.active_contract.is_unlimited ? '(Бессрочно)' : `(до ${ this.$func.getDate(item.active_contract.end_date) })`
                ]

                return `<a href="${ link.href }" class="link" target="_blank">${ text.join(' ') } </a>`
            }
            return '-'
        },
        getPositionName(item) {
            if (item.position) {
                return item.position.title
            }
            return '-'
        },
        getLaborRelation(item) {
            if (item.labor_relation) {
                return item.labor_relation.title
            }
            return '-'
        },
    }
}
</script>

<style scoped>

</style>
