import Buttons from './_buttons'
import Menu from './_menu'
import Interface from './_interface'
import Table from './_table'
import Auth from './_auth'
import Pages from './_pages'


export default {
    dashboard: {
        title: 'Панель управления',
        exit: 'Выйти',
    },

    auth: Auth,
    menu: Menu,
    buttons: Buttons,
    interface: Interface,
    table: Table,
    pages: Pages,
}
