<template>

</template>

<script>
import Table from "./Table.vue";
import Thead from "./Thead.vue";
import TableFilter from "./TableFilter.vue";

export default {
    name: "TableList",
    components: {
        Table,
        Thead,
        TableFilter,
    },
    data() {
        return {
            // Заголовки таблицы
            tableHeaderChange:0,
            tableHeader: [
                { label: '#', is_sort: false },
                { label: 'ID', key: 'id', is_sort: true },
            ],
            // Чекбоксы в таблице
            checked: [],

            // Фильтр
            filterSearch: {},
            filter: {},
            filterOpen:false,

            // Пагинация
            apiList: null,
            listItems: [],
            page: 1,
            last_page: 1,


            // Страница
            dashboardTitle:'',
            pageRoute: '',
            pageRouteCreate: '',
            pageRouteEdit: '',

            paginateCount: 20,
        }
    },

    mounted() {

    },
    methods: {
        setFilters() {
            if (this.$route.query.page) {
                this.page = Number(this.$route.query.page)
            }

            let counter = this.$cookies.get(this.$route.path+'_counter')
            if (counter) {
                this.paginateCount = Number(counter)
            }

            let header = this.$cookies.get(this.$route.path+'_table')
            if (header) {
                for (let key in header) {
                    let index = this.tableHeader.findIndex(item => item.key === key)
                    if (index !== -1) {
                        this.tableHeader[index].sort = header[key]
                    }
                }
                this.tableHeaderChange++
            }

            let filter = this.$cookies.get(this.$route.path+'_filter')
            if (filter) {
                this.filterSearch = filter
                for (let key in this.filterSearch) {
                    if (this.filter[key]) {
                        this.filter[key].value = this.filterSearch[key]
                    }
                }
                this.filterOpen = true
            }
        },

        // Установить название страницы
        setDashboardTitle() {
            this.$store.commit('setDashboardTitle', this.dashboardTitle)
        },
        // Получить справочники страницы
        getDictionaries() {
            return axios.get(this.apiList, {
                params: {
                    type: 'getDictionaries',
                }
            })
        },
        // Получить список
        getData() {
            let sort = []
            this.tableHeader.map(item => {
                if (item.is_sort && item.key && item.sort) {
                    sort.push({ key: item.key, sort: item.sort })
                }
            })
            this.$store.commit('setLoading', true)
            axios.get(this.apiList, {
                params: {
                    page: this.page,
                    paginate: this.paginateCount,
                    filter: this.filterSearch,
                    sort: sort
                }
            })
            .then(({data}) => {
                if (data.data.length === 0 && this.page > 1) {
                    this.setPage(data.last_page)
                    return
                }
                this.listItems = data.data
                this.last_page = data.last_page
            })
            .finally(() => {
                this.$store.commit('setLoading', false)
            })
        },

        // Открытие модального окна
        openModal (modal) {
            if (this.checked.length > 0) {
                this[modal] = true
            } else {
                this.$store.commit('setNotification', {type: 'error', message: 'Ничего не выбрано'})
            }
        },
        // Сортировка по заголовкам таблицы
        sortBy (index, sort) {
            this.tableHeader.map(item => {
                item.sort = null
                return item
            })
            this.tableHeader[index].sort = sort
            this.applyHeader()
            this.getData()
        },
        // Применить сортировку и сохранить в COOKIE
        applyHeader() {
            let header = {}
            this.tableHeader.map(item => {
                header[item.key] = item.sort
            })
            this.$cookies.set(this.$route.path+'_table', header)
        },
        // Применить фильтр и сохранить в COOKIE
        applyFilter() {
            for (let key in this.filter) {
                this.filterSearch[key] = this.filter[key].value
            }
            this.page = 1
            this.$cookies.set(this.$route.path+'_filter', this.filterSearch)
            this.setPage(this.page)
        },

        // Сбросить фильтр
        resetFilter() {
            this.filterSearch = {}
            for (let key in this.filter) {
                this.filter[key].value = null
            }
            this.page = 1
            this.$cookies.remove(this.$route.path+'_filter')
            this.getData()
        },
        // Выбрать страницу
        setPage(page, paginate = null) {
            if (paginate) {
                this.paginateCount = paginate
            }
            this.$cookies.set(this.$route.path+'_counter', this.paginateCount)

            if (this.page !== page) {
                this.$router.push({name: this.pageRoute, query: {page: page}})
            }
            this.page = page
            this.getData()
        },
        // Перейти к созданию
        goToCreate() {
            this.$router.push({name: this.pageRouteCreate})
        },
        // Перейти к редактированию
        goToElement(id) {
            this.$router.push({name: this.pageRouteEdit, params: {id: id}})
        },
        // Перейти к редактированию
        getLinkEdit(id) {
            let link = this.$router.resolve({name: this.pageRouteEdit, params: {id: id}})
            return link.href
        }
    }
}
</script>

<style scoped>

</style>
