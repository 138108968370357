<template>
    <div class="authPage">

        <div class="title">
            {{ $store.state.systemTitle }}
        </div>

        <div class="loginForm" @keyup.enter="enter">
            <div class="formBlock">
                <label>
                    <p class="label">Email</p>
                    <input class="input" type="text" v-model="login" placeholder="Email">
                </label>
            </div>
            <div class="formBlock">
                <label>
                    <p class="label">Пароль</p>
                    <input class="input" type="password" v-model="password" placeholder="Пароль">
                </label>
            </div>

            <div class="formBlock">
                <button class="button btn-success" @click="enter">Войти</button>
                <template v-if="verifyError">
                    <button class="button btn-primary" @click="resend">Отправить ссылку повторно</button>
                </template>
            </div>

            <div class="formBlock">
                <p class="errorNotify" v-if="showError">{{ errorNotify }}</p>
                <p class="successNotify" v-if="showSuccess">{{ successNotify }}</p>
            </div>
        </div>

        <div class="links">
            <router-link :to="{name: 'auth.forgot_password'}">Забыли пароль?</router-link>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "Login",
    data() {
        return {
            login: '',
            password: '',
            showError: false,
            errorNotify: null,
            showSuccess: false,
            successNotify: null,
            type: 'Auth',

            verifyError: false,
        }
    },
    watch: {
        errorNotify(val) {
            if (val !== null) {
                this.showError = true
            } else {
                this.showError = false
            }
            this.errorNotify = val
        },
        successNotify(val) {
            if (val !== null) {
                this.showSuccess = true
            } else {
                this.showSuccess = false
            }
            this.successNotify = val
        }
    },
    methods: {
        enter() {
            this.errorNotify = null
            this.successNotify = 'Входим в систему...'
            axios.post('/api/auth', {
                type: this.type,
                login: this.login,
                password: this.password,
            })
            .then(({data}) => {
                if (data.success) {
                    this.successNotify = 'Успешно!'
                    this.$store.dispatch('AUTH_LOGIN', data.token)
                    this.$router.push({ name: 'dashboard' })
                } else if (data.type === 'verifyError') {
                    this.verifyError = true
                    this.successNotify = null
                    this.errorNotify =  data.message
                } else {
                    this.successNotify = null
                    this.errorNotify = data.message
                }
            })
            .catch((error) => {
                this.successNotify = null
                this.errorNotify = 'Произошла ошибка'
            })
        },
        resend() {
            this.errorNotify = null
            this.successNotify = 'Отправляем ссылку...'
            axios.post('/api/auth', {
                type: 'ResendVerifyEmail',
                user: {
                    email: this.login,
                    password: this.password,
                },
            })
            .then(({data}) => {
                if (data.success) {
                    this.successNotify = data.message
                } else {
                    this.successNotify = null
                    this.errorNotify = data.message
                }
            })
            .catch((error) => {
                this.successNotify = null
                this.errorNotify = 'Произошла ошибка'
            })
        }
    }
}
</script>

<style scoped>

</style>
