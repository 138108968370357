<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.projectContract'),

            filter: {
                project_id: { value: null, type: 'text'},
                number: { value: null, type: 'text'},
            },

            tableHeader: [
                { key: 'id', is_sort: true },
                { key: 'project_id', is_sort: true, classes: 'text-l wrap' },
                { key: 'number', is_sort: true, classes: 'text-l' },
                { key: 'end_date', is_sort: true, classes: 'text-l' },
            ],

            tableBody: {
                project_id: { type: 'function', function: this.getProjectName },
                end_date: { type: 'function', function: this.getEndDate },
            },

            apiList: this.$store.getters.getApiUrl('project.contract'),
            pageRoute: 'project.contract',
            pageRouteCreate: 'project.contract.create',
            pageRouteEdit: 'project.contract.edit',

            pagePermission: 'project.contract',
        }
    },
    methods: {
        getProjectName(item) {
            let text = item.project.title
            if (item.project.user) {
                text = `${ item.project.title} (отв. ${ item.project.user.short_name })`
            }
            return text
        },
        getEndDate(item) {
            return this.$func.getDate(item.end_date)
        },
    }
}
</script>

<style scoped>

</style>
