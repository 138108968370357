<template>
    <div class="table-filter" @keyup.enter="applyFilter">
        <div class="w25" v-for="(item, key) in filter">
            <div class="formBlock">
                <p class="label">{{ item.label }}</p>
                <input type="number" class="input"
                       v-if="item.type==='number'"
                       :placeholder="item.label"
                       v-model="filter[key].value">
                <input type="text" class="input"
                       v-if="item.type==='text'"
                       :placeholder="item.label"
                       v-model="filter[key].value">
                <select v-model="filter[key].value" class="select"
                        v-else-if="item.type==='select'">
                    <option :value="null">
                        {{ $ml.get('interface.not_selected') }}
                    </option>
                    <option v-for="(option, id) in item.options" :value="option.id">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <button class="button btn-primary" @click="applyFilter"
                :title="$ml.get('buttons.search')">
            <span class="ico-mi icon-search"></span>
        </button>
        <button class="button btn-danger" @click="resetFilter"
                :title="$ml.get('buttons.reset')">
            <span class="ico-mi icon-clear"></span>
        </button>
    </div>
</template>

<script>
export default {
    name: "TableFilter",
    props: {
        filterOpen: {
            type: Boolean,
            default: false
        },
        filter: {
            type: Object,
            default: null
        }
    },
    watch: {
        filterOpen (val) {
            this.isFilterOpen = val
        }
    },
    data() {
        return {
            isFilterOpen: false
        }
    },
    methods: {
        applyFilter() {
            this.$emit('applyFilter')
        },
        resetFilter() {
            this.$emit('resetFilter')
        },
    }
}
</script>

<style scoped>

</style>
