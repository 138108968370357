export default {
    save: 'Сохранить',
    save_and_close: 'Сохранить и закрыть',
    create: 'Создать',
    edit: 'Изменить',
    delete: 'Удалить',
    back: 'Назад',
    add: 'Добавить',

    search: 'Найти',
    reset: 'Сбросить',
    clear: 'Очистить',

    actions: 'Действия',
    mass_action: 'Массовые действия',

}
