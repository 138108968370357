export default {
    labels: {
        forgot_password: 'Забыли пароль?',
        register: 'Зарегистрироваться',
        password: 'Пароль',
        confirm_password: 'Повторите пароль',
        login: 'Логин',
        enter: 'Входим в систему...',
        send_link: 'Отправляем ссылку...',
        resend_link: 'Отправить ссылку повторно',
        search_user: 'Ищем пользователя...',
    },

    buttons: {
        enter: 'Войти',
        recovery_password: 'Восстановить пароль',
    }
}
