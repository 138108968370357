<template>
    <div class="modalWindow successModal" ref="modal">
        <div class="modalClose" @click="closeModal"></div>
        <div class="modalContainer">
            <div class="header">
                {{ $ml.get('interface.success') }}
                <div class="close" @click="closeModal"></div>
            </div>
            <div class="body">{{ message }}</div>
            <div class="footer">
                <button class="button" @click="closeModal">
                    {{ $ml.get('interface.cancel') }}
                </button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "SuccessModalWindow",
    data() {
        return {
            title: '',
            messageModal: '',
        }
    },
    methods: {
        openModal() {
            this.$refs.modal.classList.add('active');
        },
        closeModal() {
            this.$store.commit('setSuccessMessage', null)
            this.$refs.modal.classList.remove('active');
        },
    },
    computed: {
        message() {
           return this.$store.getters.getSuccessMessage
        }
    },
    watch: {
        message (newVal, oldVal) {
            if (newVal !== null) {
                this.openModal()
            }
        }
    }
}
</script>

<style scoped>

</style>
