export default {
    title_list: 'Меню сайта',
    title_page: 'Пункт меню сайта',
    title_create_page: 'Создать пункт меню сайта',
    title_edit_page: 'Изменить пункт меню сайта',

    labels: {

    },

    filter: {
        labels: {
            id: 'ID',
            title: 'Название на русском',
        }
    },

    table: {
        labels: {
            id: 'ID',
            type_value: 'Тип',
            title: 'Русское название',
            title_en: 'Английское название',
            parent: 'Родительский пункт',
        }
    },

    buttons: {

    },

    form: {
        title: 'Русское название',
        title_en: 'Английское название',
        type: 'Тип контента',
        content_id: 'Контент',
        parent_id: 'Родительский пункт меню',
        visible: 'Отображается',
        position: 'Порядок',
    },
}
