<template>
    <div class="default-page">

        <div class="buttons">
            <button class="button btn-primary"
                    @click="saveForm">
                {{ $ml.get('buttons.save') }}
            </button>

            <button class="button btn-primary" @click="goToList">
                {{ $ml.get('buttons.back') }}
            </button>

            <button class="button btn-danger"
                    v-if="this.element && this.element.id && getPermissionSection('delete')"
                    @click="openConfirmDelete = true">
                {{ $ml.get('buttons.delete') }}
            </button>

            <button v-for="(button, index) in buttons"
                    :class="['button', button.class]"
                    @click="button.function">
                {{ button.label }}
            </button>
        </div>

        <div class="block" :key="change">
            <div class="title">{{ pageTitle }}</div>
            <div class="body flex-row-wrap">
                <div :class="getClass(label)"
                     v-for="(label, key) in labels">
                    <div class="formBlock">
                        <p :class="['label', {'required' : form_required.includes(label)}]">
                            {{ form_labels[label] }}
                            <template v-if="form_required.includes(label)">*</template>
                        </p>
                        <template v-if="getTypeForm(label) === 'number'">
                            <input type="number"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>
                        <template v-if="getTypeForm(label) === 'password'">
                            <input type="text"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]" :key="passChange">

                            <span class="helper" style="border-bottom: 1px dotted #ccc; cursor:pointer;" @click="genPass">Сгенерировать пароль</span>
                        </template>

                        <template v-if="getTypeForm(label) === 'mask'">
                            <input type="text"
                                   class="input"
                                   v-maska
                                   :data-maska="typeForm[label].mask"
                                   :data-maska-tokens="typeForm[label].maskTokens ? typeForm[label].maskTokens : null"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>

                        <template v-if="getTypeForm(label) === 'string'">
                            <div class="input disabled" v-if="element[label]">{{ element[label] }}</div>
                        </template>
                        <template v-if="getTypeForm(label) === 'date-string'">
                            <div class="input disabled">
                                <template v-if="element[label]">
                                    {{ $func.getDateTime(element[label]) }}
                                </template>
                                <template v-else>&nbsp;</template>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'date'">
                            <date-picker
                                lang="ru"
                                valueType="format"
                                format="DD.MM.YYYY"
                                v-model="element[label]"
                                :placeholder="form_labels[label]"
                            />
                        </template>
                        <template v-if="getTypeForm(label) === 'datetime'">
                            <date-picker
                                lang="ru"
                                type="datetime"
                                valueType="format"
                                format="DD.MM.YYYY HH:mm:ss"
                                v-model="element[label]"
                                :placeholder="form_labels[label]"
                            />
                        </template>
                        <template v-if="getTypeForm(label) === 'link'">
                            <textarea
                                class="textarea"
                                v-model="element[label]"
                                :placeholder="form_labels[label]">
                            </textarea>
                        </template>
                        <template v-if="getTypeForm(label) === 'textarea'">
                            <textarea
                                class="textarea"
                                v-model="element[label]"
                                :placeholder="form_labels[label]">
                            </textarea>
                        </template>
                        <template v-if="getTypeForm(label) === 'input'">
                            <input type="text"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>
                        <template v-if="getTypeForm(label) === 'select'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    :label="getSelectLabel(label)"
                                    v-model="element[label]"
                                    :options="dictionaries[label]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'boolean'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    label="title"
                                    v-model="element[label]"
                                    :options="selectBooleanOptions"
                                    :placeholder="form_labels[label]">
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'custom-select'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    :label="typeForm[label].label"
                                    :value="element[label]"
                                    v-model="element[label]"
                                    :options="dictionaries[typeForm[label].dictionary]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'select-tags'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    label="title"
                                    :multiple="true"
                                    :taggable="true"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :value="element[label]"
                                    v-model="element[label]"
                                    :options="dictionaries[label]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="title">Роли</div>
            <div class="body">
                <treeselect v-model="rolesSelected"
                            value-consists-of="ALL_WITH_INDETERMINATE"
                            sort-value-by="INDEX"
                            :multiple="true"
                            placeholder="Выбрать..."
                            :options="roles" />
            </div>
        </div>

        <div class="block">
            <div class="title">Права</div>
            <div class="body">
                <treeselect v-model="permissionsSelected"
                            value-consists-of="ALL_WITH_INDETERMINATE"
                            sort-value-by="INDEX"
                            :multiple="true"
                            placeholder="Выбрать..."
                            :options="permissions" />
            </div>
        </div>

        <div class="block" v-if="element.id">
            <div class="title">Договора с пользователем</div>
            <div class="body">
                <div class="table-scroll__wrapper">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Номер договора</th>
                            <th>Дата окончания</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in contracts">
                            <td><a :href="getContractLink(item)" class="link">{{ item.id }}</a></td>
                            <td>{{ item.number }}</td>
                            <td>
                                <template v-if="item.is_unlimited">
                                    Бессрочный
                                </template>
                                <template v-else>
                                    {{ $func.getDate(item.end_date) }}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="!contracts.length">
                            <td colspan="3">Нет ни одного договора</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <ModalWindow :open="openConfirmDelete"
                     @closeModal="openConfirmDelete = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                {{ $ml.get('interface.confirm_action_delete') }}
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteForm">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

    </div>
</template>

<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {generatePassword} from "../../../passGen";
import dayjs from "dayjs";


export default {
    name: "Form",
    mixins: [FormDefault],
    components: {
        Treeselect
    },
    data() {
        return {
            lang: this.$ml.get('pages.user'),
            buttons: [
            ],

            typeForm: {
                email_verified_at: 'datetime',
                last_login_at: 'date-string',
                dob: 'date',
                passport_issue_date: 'date',
                language: 'select',
                activated: 'select',
                position_id: 'select',
                type_labor_relation_id: 'select',
                password: 'password',
                phone: {
                    type: 'mask',
                    mask: ['+7 ### ###-##-##'],
                    //maskTokens: { H: { 'pattern': /\+7|8/, multiple: true }}
                },
            },

            element: {
                password: null,
                email_verified_at: null,
                dob: null,
                passport_issue_date: null,
            },

            form_required: [
                'name',
                'email',
            ],

            pageRouteList: 'user',
            pageRouteEdit: 'user.edit',
            pageApi: 'user',
            pagePermission: 'user',

            permissions: [],
            permissionsSelected: [],

            roles: [],
            rolesSelected: [],

            contracts: [],

            passChange: 0,
        }
    },
    methods: {
        generatePassword,
        getContractLink(item) {
            let link = this.$router.resolve({ name: 'contract.edit', params: {id: item.id}})
            return link.href
        },
        genPass() {
            this.element.password = generatePassword()
            this.passChange++
        },

        setExtraData(data) {
            this.permissions = data.permissions
            this.permissionsSelected = data.permissionsSelected

            this.roles = data.roles
            this.rolesSelected = data.rolesSelected

            this.contracts = data.contracts

            if (this.element.dob) {
                this.element.dob = dayjs(this.element.dob).format('DD.MM.YYYY')
            }
            if (this.element.email_verified_at) {
                this.element.email_verified_at = dayjs(this.element.email_verified_at).format('DD.MM.YYYY HH:mm:ss')
            }
        },
        setExtraDataCreate() {
            axios.get(this.$store.getters.getApiUrl(this.pageApi), {
                params: {
                    type: 'getRolesPermissions',
                }
            }).then((response) => {
                let data = response.data
                this.permissions = data.permissions
                this.roles = data.roles
            })
        },
        appendDataSave() {
            return {
                permissions_selected: this.permissionsSelected,
                roles_selected: this.rolesSelected
            }
        },
        getClass(key) {
            if (['address', 'passport_issue_org', 'bank'].includes(key)) {
                return 'w100'
            }
            return 'w33'
        },
    }
}
</script>

<style scoped>

</style>
