export default {
    title_list: 'Роли администраторов',
    title_page: 'Роль администратора',
    title_create_page: 'Создать роль администратора',
    title_edit_page: 'Изменить роль администратора',

    filter: {
        labels: {
            slug: 'Ключ',
            title: 'Название',
        }
    },

    table: {
        labels: {
            id: 'ID',
            slug: 'Ключ',
            title: 'Название',
        }
    },

    buttons: {

    },

    form: {
        slug: 'Ключ',
        title: 'Название',

    },
}
