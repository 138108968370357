<template>
    <div :class="['slideModal', {'open' : open}]">
        <div class="slideModalClose" @click="returnBack(null)"></div>
        <div class="modalContainer">
            <div class="head">
                <div class="title">
                    {{ titleModal }}
                </div>
            </div>

            <div class="body">
                <div class="back">
                    <div class="icon" @click="returnBack(null)"></div>
                    <div class="text" v-for="item in path" @click="returnBack(item)" :title="item.title">
                        {{ item.title }}
                    </div>
                </div>

                <div class="block">
                    <div class="title">Папка</div>
                    <div class="body">
                        <div class="formBlock">
                            <p :class="['label']">Название папки</p>
                            <input
                                type="text"
                                class="input"
                                v-model="folder.title"
                                placeholder="Название папки">
                        </div>
                    </div>
                </div>

                <!-- Права -->
                <div class="block" v-if="folder.id && availableEditPermission">
                    <div class="title">Права на папку</div>
                    <div class="body">
                        <div>
                            <button :class="['button', 'btn-primary']" @click="openModalUserFunction()">
                                Добавить пользователя
                            </button>
                        </div>
                        <div class="table-scroll__wrapper">
                            <table class="table" :key="permissionCounter">
                                <thead>
                                <tr>
                                    <th>Пользователь</th>
                                    <th>Чтение</th>
                                    <th>Запись</th>
                                    <th>#</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in permissions" >
                                    <td>{{ item.user.all_name }}</td>
                                    <td><input type="checkbox" v-model="item.read" @change="changePermission(item)"></td>
                                    <td><input type="checkbox" v-model="item.write" @change="changePermission(item)"></td>
                                    <td>
                                        <button class="button btn-danger" @click="openConfirmDeleteUser(item)">
                                            <span class="icon ico-i icon-delete"></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="!permissions.length">
                                    <td colspan="4">Нет ни одного пользователя</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

<!--                &lt;!&ndash; Папки &ndash;&gt;
                <div class="block" v-if="folder.id">
                    <div class="title">Вложенные папки</div>
                    <div class="body">
                        <div>
                            <button :class="['button', 'btn-primary']" @click="openSubFolder(null)">
                                Добавить папку
                            </button>
                        </div>
                        <div class="table-scroll__wrapper">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Название</th>
                                    <th>#</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in folders">
                                    <td class="text-l wrap">{{ item.title }}</td>
                                    <td>
                                        <button class="button btn-primary" @click="openSubFolder(item)">
                                            <span class="icon ico-i icon-edit"></span>
                                        </button>
                                        <button class="button btn-danger" @click="deleteFolder(item)">
                                            <span class="icon ico-i icon-delete"></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="!folders.length">
                                    <td colspan="2">Нет ни одной папки</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>-->


                <!-- Файловый менеджер -->
<!--                <div class="block" v-if="folder.id">
                    <div class="title">Файловый менеджер</div>
                    <div class="body">
                        <button :class="['button', 'btn-primary']" @click="openModalFiles = true">
                            Добавить файл
                        </button>
                        <div class="fileManager tableView">
                            <div class="file" v-if="parentFolder">
                                <div class="info"  :title="parentFolder.title" @click="returnBack(parentFolder)">
                                    <div :class="['icon', 'ico-fa4', 'icon-folder']"></div>
                                    <div class="text">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="file" v-for="item in files">
                                <div class="info" :title="item.title" @click="openFile(item)">
                                    <div :class="['icon', 'ico-fa4', 'icon-'+getIconByExtension(item)]"></div>
                                    <div class="text">
                                        {{ item.title }}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>-->


            </div>
            <div class="bottom">
                <button class="button btn-success" @click="saveFolder">
                    Сохранить
                </button>
                <button class="button btn-danger" @click="deleteFolder(folder)" v-if="folder.id">
                    Удалить
                </button>
            </div>

            <div :class="['loadingModal', {'active' : loading}]" ref="loadWindow">
                <div>
                    <img src="/images/dual_loader.gif">
                </div>
            </div>
        </div>

        <ModalWindow :open="openModalFiles"
                     @closeModal="openModalFiles = false">
            <template slot="title">
                Выбрать файлы
            </template>
            <template slot="body">
                <div class="drag-in-drop"
                     @dragover="dragoverFile"
                     @dragleave="dragleaveFile"
                     @drop="(e) => dropFile(e, 'documents')"
                     @click="$refs.document.click()">
                    <input type="file"
                           @change="onChangeFile('documents')"
                           ref="document"
                           multiple
                           :accept="acceptDocuments" />
                    <div class="label">Перетащите файлы или кликните для выбора</div>
                </div>
                <div class="documents">
                    <div class="table-scroll__wrapper">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="w90">Файл</th>
                                <th class="w10">#</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(document,index) in selectedFiles"
                                :key="index">
                                <td class="text-l">
                                    <template v-if="document.url">
                                        <a class="link"
                                           :href="document.url"
                                           target="_blank">
                                            {{ document.title }}
                                        </a>
                                    </template>
                                    <template v-else>
                                        {{ document.title }}
                                    </template>
                                </td>
                                <td>
                                    <button class="button btn-danger" @click="deleteSelectedDocument(index)">
                                        <span class="icon ico-i icon-delete"></span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template slot="buttons">
                <button class="button btn-success" @click="addFiles">
                    Добавить
                </button>
            </template>
        </ModalWindow>

        <select-users @selected="selectedUsers"
                      :modal-open="openModalUser"
                      @close="openModalUser = false">
        </select-users>

        <ModalWindow :open="openModalConfirmDeleteUser"
                     @closeModal="openModalConfirmDeleteUser = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                <template v-if="confirmDeleteUser">
                    Вы действительно хотите удалить разрешения для пользователя "{{ confirmDeleteUser.user.short_name }}"?
                </template>
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deletePermission(confirmDeleteUser)">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

    </div>
</template>

<script>
import ModalWindow from "../../Utils/ModalWindow.vue";
import Multiselect from "vue-multiselect";
import SelectUsers from "./SelectUsers.vue";
export default {
    name: "SelectFolder",
    components: {
        SelectUsers,
        ModalWindow,
        Multiselect
    },
    props: {
        modalParentFolder: {
            type: Object,
            default: () => { return null }
        },
        modalFolder: {
            type: Object,
            default: () => { return this.defaultFolder() }
        },
        modalOpen: {
            type: Boolean,
            default: false
        },
        projectId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            parentFolder: this.modalParentFolder,
            folder: this.modalFolder,
            folders: [],
            open: this.modalOpen,
            path: [
                {
                    title: 'К проекту',
                    id: 'close',
                }
            ],
            loading: false,

            word: ['doc', 'docx'],
            excel: ['xls', 'xlsx'],
            pdf: ['pdf'],
            txt: ['txt'],
            image: ['jpg', 'jpeg', 'png', 'bmp', 'gif'],
            archive: ['zip', 'rar', 'gz'],

            files: [
                /*{title: 'Папка', text_type: 'folder', extension: ''},
                {title: 'Докds fdsf sd fsdf sdf sdfsd fsdf sdf  f sd умент.docx', text_type: 'file', extension: 'docx'},
                {title: 'Таблица.xlsx', text_type: 'file', extension: 'xlsx'},
                {title: 'Документ.pdf', text_type: 'file', extension: 'pdf'},
                {title: 'Тект.txt', text_type: 'file', extension: 'txt'},
                {title: 'Картинка.jpeg', text_type: 'file', extension: 'jpeg'},
                {title: 'Архив.zip', text_type: 'file', extension: 'zip'},*/
            ],

            permissionCounter: 0,
            openModalUser: false,
            selectUsers: [],
            selectUser: null,
            permissions: [],


            // Файлы
            openModalFiles: false,
            selectedFiles: [],

            openModalConfirmDeleteUser: false,
            confirmDeleteUser: null,
        }
    },
    watch: {
        modalParentFolder(val) {
            this.parentFolder = val
        },
        modalFolder(val) {
            if (val.id) {
                this.folder = val
                this.folders = this.folder.folders ?? []
                this.path.push(this.folder)
                this.syncFolder()
            } else {
                this.path = this.defaultPath()
                this.folder = this.defaultFolder()
                this.folders = []
            }
        },
        modalOpen(val) {
            this.open = val
        }
    },
    computed: {
        availableEditPermission() {
            if (this.$store.getters.isAdmin()
                || this.$store.getters.checkPermission('project.edit')
                || this.$store.getters.checkPermission('project.create')) {
                return true
            }
            return false
        },
        acceptDocuments() {
            let array = []
            array.push(...this.word)
            array.push(...this.excel)
            array.push(...this.pdf)
            array.push(...this.txt)
            array.push(...this.image)
            array.push(...this.archive)
            return '.' + array.join(',.')
        },
        titleModal() {
            if (this.folder && this.folder.id) {
                return `Изменить папку "${ this.folder.title }" (ID ${ this.folder.id })`
            }
            return 'Добавить папку'
        }
    },
    methods: {
        openConfirmDeleteUser(item) {
            this.confirmDeleteUser = item
            this.openModalConfirmDeleteUser = true
        },
        selectedUsers(val) {
            val.map(el => {
                this.addUser(el)
            })
        },
        deletePermission(item) {
            let index = this.permissions.findIndex(el => el.user_id === item.user_id)
            if (index !== -1) {
                this.permissions.splice(index, 1)
            }
            this.openModalConfirmDeleteUser = false
        },
        addFiles() {
            this.loading = true
            this.openModalFiles = false
            let data = new FormData();

            data.append('projectId', this.projectId)
            data.append('folderId', this.folder.id)

            this.selectedFiles.map((el, index) => {
                data.append(`file_${ index }`, el.file)
            })

            axios
                .post(this.$store.getters.getApiUrl('project.file'), data)
                .then(({ data }) => {
                    this.selectedFiles = []
                })
                .finally(() => {
                    this.syncFolder()
                    this.loading = false
                })
        },

        deleteSelectedDocument(index) {
            this.selectedFiles.splice(index, 1)
        },
        // Событие при перетаскивании файла
        dragoverFile(event) {
            event.preventDefault();
            event.currentTarget.classList.add('over');
        },
        // Событие при убирании курсора при перетаскивании
        dragleaveFile(event) {
            event.currentTarget.classList.remove('over');
        },
        // Событие добавления файла
        dropFile(event, type) {
            // Снимаем стиль
            event.currentTarget.classList.remove('over')
            event.preventDefault()

            // Помещаем изображение в input
            if (type === 'images') {
                this.$refs.image.files = event.dataTransfer.files
            } else if (type === 'documents') {
                this.$refs.document.files = event.dataTransfer.files
            }
            // Вызываем событие изменения
            this.onChangeFile(type)
        },
        // Изменение при добавлении файла
        onChangeFile(type) {
            let files = []
            files = this.$refs.document.files
            files = Array.from(files)
            files.map(file => {
                this.selectedFiles.push({
                    file: file,
                    title: file.name,
                })
            })
        },
        changePermission(item) {
            if (item.write) {
                item.read = true
            }
            this.permissionCounter++
        },
        addUser(user) {
            let find = this.permissions.find(el => el.user_id === user.id)
            if (find) {
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: `Пользователь ${ user.short_name } уже присутствует`
                })
            } else {
                this.permissions.push({
                    file_id: this.folder.id,
                    user_id: user.id,
                    user: user,
                    read: true,
                    write: false,
                })
            }
            this.openModalUser = false
        },
        openModalUserFunction() {
            this.loading = true
            axios
                .get(this.$store.getters.getApiUrl('project.users'))
                .then(({ data }) => {
                    this.selectUsers = data.users
                    this.openModalUser = true
                })
                .finally(() => {
                    this.loading = false
                })
        },


        openFile(item) {
            if (item.text_type === 'folder') {
                this.openSubFolder(item)
            } else {
                // Скачать файл
                let a = document.createElement('a');
                a.href = item.url;
                //a.target = "_blank";
                a.click();
            }
        },
        getIconByExtension(item) {

            if (item.text_type === 'folder') {
                return 'folder'
            }

            let extension = item.extension
            if (this.word.includes(extension)) {
                return 'word'
            }
            if (this.excel.includes(extension)) {
                return 'excel'
            }
            if (this.image.includes(extension)) {
                return 'image'
            }
            if (this.pdf.includes(extension)) {
                return 'pdf'
            }
            if (this.txt.includes(extension)) {
                return 'txt'
            }
            if (this.archive.includes(extension)) {
                return 'archive'
            }
            return 'other'
        },
        defaultFolder() {
            return {
                title: null,
            }
        },
        defaultPath() {
            return [
                {
                    title: 'К проекту',
                    id: 'close',
                }
            ]
        },
        syncFolder() {
            this.loading = true
            axios
                .get(this.$store.getters.getApiUrl('project.folder', this.folder.id))
                .then(({data}) => {
                    if (data.success) {
                        this.folder = data.folder
                        this.folders = data.folder.folders ?? []
                        this.permissions = data.folder.permissions

                        this.files = []
                        this.files.push(...this.folders)
                        this.files.push(...data.files)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        returnBack(item = null) {
            if (!item || item.id === 'close') {
                this.open = false
                this.path = this.defaultPath()
                this.folder = this.defaultFolder()
                this.folders = []
                this.files = []
                this.parentFolder = null
                this.$emit('close')
            } else {
                let index = this.path.findIndex(el => item.id === el.id)
                this.path.splice(index, this.path.length - index)
                this.parentFolder = this.path[index - 1]
                this.folder = item
                this.folders = item.folders ?? []
                this.path.push(this.folder)
                this.syncFolder()
            }
        },
        openSubFolder(item = null) {
            this.parentFolder = JSON.parse(JSON.stringify(this.folder))
            this.files = []
            this.permissions = []
            // Создание
            if (!item) {
                this.folder = this.defaultFolder()
                this.permissions = []
            } else {
                this.folder = item
                this.folders = item.folders ?? []
                this.path.push(this.folder)
                this.syncFolder()
            }
        },
        saveFolder() {
            if (this.parentFolder && this.parentFolder.id !== 'close' && this.parentFolder.id !== 'root') {
                this.folder.parent_id = this.parentFolder.id
            }
            this.loading = true
            axios
                .post(this.$store.getters.getApiUrl('project.folder'), {
                    folder: this.folder,
                    project_id: this.projectId,
                    permissions: this.permissions
                })
                .then(({ data }) => {
                    if (data.success) {
                        // Только что созданная папка
                        if (!this.folder.id) {
                            this.path.push(this.folder)
                        }
                        this.folder = data.folder
                        this.folders = data.folder.folders ?? []
                        this.permissions = data.folder.permissions

                        this.$emit('updateRootFolders', data.folders)
                    } else {
                        this.$store.commit('setNotification', {
                            type: 'error',
                            message: data.message ?? 'Ошибка'
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteFolder(item) {
            this.loading = true
            axios
                .delete(this.$store.getters.getApiUrl('project.folder', item.id))
                .then(({ data }) => {
                    // Если текущая папка открыта
                    if (this.path[this.path.length - 1].id === item.id) {
                        this.returnBack(this.parentFolder)
                    } else {
                        // Если удаление из списка
                        this.syncFolder()
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

    }
}
</script>


<style scoped>

</style>
