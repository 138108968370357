export default {
    title_list: 'Договора проектов',
    title_page: 'Договор проекта',
    title_create_page: 'Создать договор проекта',
    title_edit_page: 'Изменить договор проекта',

    filter: {
        labels: {
            project_id: 'Проект',
            number: 'Номер договора',
        }
    },

    table: {
        labels: {
            id: 'ID',
            project_id: 'Проект',
            number: 'Номер договора',
            end_date: 'Дата окончания',
        }
    },

    buttons: {

    },

    form: {
        project_id: 'Проект',
        number: 'Номер договора',
        end_date: 'Дата окончания',
        active: 'Действителен',
        note: 'Примечание',
    },
}
