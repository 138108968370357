<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.project'),

            filter: {
                title: { value: null, type: 'text'},
                user_id: { value: null, type: 'text'},
                status_id: { value: null, type: 'select', options: []},
            },

            tableHeader: [
                { key: 'id', is_sort: true },
                { key: 'status_id', is_sort: true, classes: 'text-l' },
                { key: 'title', is_sort: true, classes: 'text-l' },
                { key: 'user_id', is_sort: true, classes: 'text-l' },
                { key: 'contract', is_sort: false, classes: 'text-l' },
                { key: 'end_date', is_sort: true, classes: 'text-l  w5' },
                { key: 'note', is_sort: true, classes: 'text-l wrap w20 ', dataClasses: 'hidden-before-3-lines' },
            ],

            tableBody: {
                status_id: { type: 'function', function: this.getStatusName },
                user_id: { type: 'function', function: this.getUserName },
                contract: { type: 'function', function: this.getContract },
                end_date: { type: 'function', function: this.getEndDate },
            },

            apiList: this.$store.getters.getApiUrl('project'),
            pageRoute: 'project',
            pageRouteCreate: 'project.create',
            pageRouteEdit: 'project.edit',

            pagePermission: 'project',
        }
    },
    mounted() {
        this.getDictionaries().then(({data}) => {
            this.dictionaries = data.dictionaries

            for (let key in this.filter) {
                if (this.dictionaries[key]) {
                    this.filter[key].options = this.dictionaries[key]
                }
            }
        })
    },
    methods: {
        getContract(item) {
            if (item.contract) {
                if (this.$store.getters.checkPermission('project.contract.edit') || this.$store.getters.checkPermission('project.contract.view_list') ) {
                    let link = this.$router.resolve({ name: 'contract.edit', params: {id: item.contract.id}})
                    return `<a href="${ link.href }" class="link" target="_blank">${ item.contract.number } (до ${ this.$func.getDate(item.contract.end_date) })</a>`
                } else {
                    return `${ item.contract.number } (до ${ this.$func.getDate(item.contract.end_date)})`
                }
            }
            return '-'
        },
        getUserName(item) {
            if (item.user) {
                if (this.$store.getters.checkPermission('user.edit') || this.$store.getters.checkPermission('user.view_list') ) {
                    let link = this.$router.resolve({ name: 'user.edit', params: {id: item.user.id}})
                    return `<a href="${ link.href }" class="link" target="_blank">${ item.user.short_name }</a>`
                } else {
                    return item.user.short_name
                }
            }
            return '-'
        },
        getStatusName(item) {
            return item.status?.title
        },
        getEndDate(item) {
            return this.$func.getDate(item.end_date)
        },
    }
}
</script>

<style scoped>

</style>
