<template>
    <div class="flex-row-wrap">
        

    </div>
</template>

<script>

export default {
    name: "Dashboard",
    components: {
    },
    data() {
        return {
            cargo: {
                all: 0,
                today: 0,
            },
            delivery: {
                all: 0,
                today: 0,
                new: 0,
            },
            cargo_issue: {
                all: 0,
                today: 0,
                new: 0,
            }
        }
    },
    created() {
        axios.post(this.$store.getters.getApiUrl('homePage'))
            .then(({data}) => {
                this.cargo = data.data.cargo
                this.delivery = data.data.delivery
                this.cargo_issue = data.data.cargo_issue
            })
    },
    mounted() {
        this.$store.commit('setDashboardTitle', 'Панель управления')
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
