export default {
    title_list: 'Категории сайта',
    title_page: 'Категория сайта',
    title_create_page: 'Создать категорию сайта',
    title_edit_page: 'Изменить категорию сайта',

    labels: {

    },

    filter: {
        labels: {
            id: 'ID',
            title: 'Название на русском',
        }
    },

    table: {
        labels: {
            id: 'ID',
            title: 'Русское название',
            slug: 'Ссылка',
            visible: 'Отображается',
        }
    },

    buttons: {

    },

    form: {
        slug: 'Ссылка',
        title: 'Русское название',
        title_en: 'Английское название',

        meta_title: 'Заголовок для поисковых систем',
        meta_description: 'Описание для поисковых систем',
        meta_keywords: 'Ключевые слова для поисковых систем',

        meta_title_en: 'Заголовок для поисковых систем на английском',
        meta_description_en: 'Описание для поисковых систем на английском',
        meta_keywords_en: 'Ключевые слова для поисковых систем на английском',

        social_title: 'Заголовок для социальных сетей',
        social_description: 'Описание для социальных сетей',

        social_title_en: 'Заголовок для социальных сетей на английском',
        social_description_en: 'Описание для социальных сетей на английском',

        social_image: 'Изображение для социальных сетей',
    },
}
