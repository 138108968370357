<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";

export default {
    name: "Form",
    mixins: [FormDefault],
    data() {
        return {
            lang: this.$ml.get('pages.permission'),
            buttons: [
            ],

            typeForm: {
                parent_id: 'select',
            },

            pageRouteList: 'permission',
            pageRouteEdit: 'permission.edit',
            pageApi: 'permission',
            pagePermission: 'user.permission',
        }
    },
    methods: {
        getSelectLabel(label) {
            return 'title'
        },
    }
}
</script>

<style scoped>

</style>
