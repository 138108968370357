<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.payment'),

            filter: {
                user_id: { value: null, type: 'text'},
                number: { value: null, type: 'text'},
            },

            tableHeader: [
                { key: 'id', is_sort: true },
                { key: 'user_id', is_sort: true, classes: 'text-l' },
                { key: 'contract_id', is_sort: true, classes: 'text-l' },
                { key: 'number', is_sort: true, classes: 'text-l' },
                { key: 'amount', is_sort: true, classes: 'text-r' },
                { key: 'created_at', is_sort: true, classes: '' },
                { key: 'note', is_sort: true, classes: 'text-l wrap' },
            ],

            tableBody: {
                user_id: { type: 'function', function: this.getUserName },
                contract_id: { type: 'function', function: this.getContract },
                created_at: { type: 'function', function: this.getDate },
            },

            apiList: this.$store.getters.getApiUrl('user.contract.payment'),
            pageRoute: 'user.contract.payment',
            pageRouteCreate: 'user.contract.payment.create',
            pageRouteEdit: 'user.contract.payment.edit',

            pagePermission: 'user.contract.payment',
        }
    },
    methods: {
        getContract(item) {
            return item.contract?.number
        },
        getUserName(item) {
            return item.user?.all_name
        },
        getDate(item) {
            return this.$func.getDate(item.created_at)
        },
    }
}
</script>

<style scoped>

</style>
