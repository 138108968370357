<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";

export default {
    name: "Form",
    mixins: [FormDefault],
    data() {
        return {
            lang: this.$ml.get('pages.payment'),
            buttons: [
            ],

            typeForm: {
                user_id: 'select',
                contract_id: 'select',
                created_at: 'datetime',
                amount: 'number',
                note: 'textarea',
            },

            element: {
                created_at: null,
                contract_id: null,
                user_id: null,
            },

            pageRouteList: 'user.contract.payment',
            pageRouteEdit: 'user.contract.payment.edit',
            pageApi: 'user.contract.payment',
            pagePermission: 'user.contract.payment',
        }
    },
    methods: {
        getSelectLabel(label) {
            if (label === 'user_id') {
                return 'all_name'
            }
            if (label === 'contract_id') {
                return 'number'
            }
        },
        getClass(label) {
            if (label === 'note') {
                return 'w100'
            }
            return 'w50'
        }
    }
}
</script>

<style scoped>

</style>
