// Assignment Code
var generateBtn = document.querySelector("#generate");

//Creating password object.
var pwdCriteria = {

    //Property for length of password
    pwdLength: 0,

    //array to hold lowercase letters
    pwdLowerCase: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l",
        "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],

    //array to hold uppercase letters
    pwdUpperCase: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L",
        "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],

    //array to hold numbers
    pwdNumber: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],

    //array to hold special characters
    pwdCharacter: ["!", "\"", "#", "$", "%", "&", "\'", "(", ")", "*", "+", ",",
        "-", ".", "/", "\\", ":", ";", "<", ">", "=", "?", "@", "[", "]", "^", "_", "`", "{", "}", "|", "~"]//32
}

//function to handle the operations to generate a new password
export function generatePassword(passwordLength = 12, upperCase = true, lowerCase = true, numbers = true,  specialChar = false) {

    //holds the password to be generated and returned
    var result = "";
    pwdCriteria.pwdLength = 0

    //keep adding characters based on criteria until pwdLength is = to the length the user set
    while (pwdCriteria.pwdLength < passwordLength) {
        //if statement to make sure the user selects at least one of the criteria
        if (lowerCase === false && upperCase === false && numbers === false && specialChar === false) {
            //alert("You must select at least one criteria of lowercase, uppercase, numbers or special characters")
            //showPrompts();
        } else {
            //if the user selected lowercase and there is still room to add characters then
            //randomly grab a lowercase letter from the array and add it to the end of result
            //update pwdLength by 1
            if (lowerCase === true && pwdCriteria.pwdLength < passwordLength) {
                var lc = pwdCriteria.pwdLowerCase[Math.floor(Math.random() * 26)]
                result = result + lc;
                pwdCriteria.pwdLength++;
            }

            //if the user selected a special character and there is still room to add characters then
            //randomly grab a apecial character from the array and add it to the end of result
            //update pwdLength by 1
            if (specialChar === true && pwdCriteria.pwdLength < passwordLength) {
                var sc = pwdCriteria.pwdCharacter[Math.floor(Math.random() * 32)]
                result = result + sc;
                pwdCriteria.pwdLength++;
            }

            //if the user selected an uppercase letter and there is still room to add characters then
            //randomly grab an uppercase letter from the array and add it to the end of result
            //update pwdLength by 1
            if (upperCase === true && pwdCriteria.pwdLength < passwordLength) {
                var uc = pwdCriteria.pwdUpperCase[Math.floor(Math.random() * 26)]
                result = result + uc;
                pwdCriteria.pwdLength++;
            }

            //if the user selected a number and there is still room to add characters then
            //randomly grab a number from the array and add it to the end of result
            //update pwdLength by 1
            if (numbers === true && pwdCriteria.pwdLength < passwordLength) {
                var num = pwdCriteria.pwdNumber[Math.floor(Math.random() * 10)]
                result = result + num;
                pwdCriteria.pwdLength++;
            }
        }
    }


    //return the generated password back to the calling function
    return result;
}
