<template>
    <div class="loadingModal" ref="loadWindow">
        <div>
            <img src="/images/dual_loader.gif">
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingModal",
    methods: {
        openModal() {
            this.$refs.loadWindow.classList.add('active');
        },
        closeModal() {
            this.$refs.loadWindow.classList.remove('active');
        },
    },
    computed: {
        open() {
            return this.$store.getters.getLoading
        }
    },
    watch: {
        open (newVal, oldVal) {
            if (newVal === true) {
                this.openModal()
            } else {
                this.closeModal()
            }
        }
    }
}
</script>

<style scoped>

</style>
