<template>
    <div :class="['slideModal', {'open' : open}]">
        <div class="slideModalClose" @click="returnBack()"></div>
        <div class="modalContainer">
            <div class="head">
                <div class="title">
                    {{ titleModal }}
                </div>
            </div>

            <div class="body" style="height: 100%;">
                <div class="back" style="margin-bottom: 10px">
                    <div class="icon" @click="returnBack()"></div>
                </div>

                <UserList @selected="(val) => { $emit('selected', val) }" :is-page-list="false" />

            </div>
        </div>
    </div>
</template>

<script>
import UserList from '../../User/User/List.vue'

export default {
    name: "SelectUsers",
    components: {
        UserList
    },
    props: {
        modalOpen: {
            type: Boolean,
            default: false
        },
        elementOptions: {
            type: Object,
            default: () => { return {} }
        },
        elementId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            open: this.modalOpen,
            loading: false,

            element: null,
        }
    },
    watch: {
        modalOpen(val) {
            this.open = val
        }
    },
    computed: {
        titleModal() {
            return 'Выбрать пользователей'
        }
    },
    methods: {
        updateElement(el) {
            this.element = el
            if (!el) {
                this.returnBack()
            }
            this.$emit('updateElement', el)
        },
        returnBack() {
            this.open = false
            this.$emit('close')
        },
    }
}
</script>


<style scoped>

</style>
