<script>
import ModalWindow from "../../ModalWindow.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import { vMaska } from "maska"

export default {
    name: "FormMethodsDefault",
    props: {
        isPageForm: {
            type: Boolean,
            default: true,
        },
        elementOptions: {
            type: Object,
            default: () => { return {} }
        },
        elementId: {
            type: Number,
            default: null,
        },
    },
    components: {
        ModalWindow,
        Multiselect,
        DatePicker,
        vMaska
    },
    data() {
        return {
            id: this.elementId ?? (this.isPageForm && this.$route.params.id) ? this.$route.params.id : null,

            isPage: this.isPageForm,

            tinyMceApi: null,
            tinyMceConfig: {},

            openConfirmDelete: false,
            openColorModal: false,

            buttons: [],

            form_labels: {},
            form_required: [],

            element: {},
            labels: {},
            dictionaries: {},

            pageRouteList: '',
            pageRouteEdit: '',
            pageApi: '',
            pageTitle: '',
            pageTitleCreate: '',
            pageTitleEdit: '',
            pagePermission: '',

            change: 0,

            typeForm: {},
            selectKeys: ['select', 'custom-select', 'select-tags', 'boolean'],

            selectBooleanOptions: [
                { id: false, title: this.$ml.get('interface.no') },
                { id: true, title: this.$ml.get('interface.yes') },
            ],

            uploadFiles: {},
            formUploadFiles: false,
        }
    },
    mounted() {
        if (this.isPage) {
            this.$store.commit('setDashboardTitle', this.pageTitleCreate)
        }
        this.$store.commit('setLoading', true)
    },
    watch: {
        elementId(val) {
            this.setNullForm()
            this.id = val
            this.$store.commit('setLoading', true)
            this.getData()
        },
        elementOptions() {
            for (let key in this.elementOptions) {
                if (this.typeForm[key] === 'select' && !this.elementOptions[key].id) {
                    this.element[key] = this.dictionaries[key].find(el => el.id === Number(this.elementOptions[key]))
                } else {
                    this.element[key] = this.elementOptions[key]
                }

            }
        }
    },
    created() {
        this.setLang();
        this.getData()
    },
    methods: {
        onChangeFile(event, key) {
            console.log(event.target.files)
            this.uploadFiles[key] = event.target.files[0]
            event.target.value = null
        },

        getPermissionSection(key) {
            return this.$store.getters.checkPermission(this.pagePermission + '.' + key)
        },

        getData() {
            axios.get(this.$store.getters.getApiUrl(this.pageApi), {
                params: {
                    type: 'getDictionaries',
                }
            }).then((response) => {
                let data = response.data
                this.dictionaries = data.dictionaries
                this.labels = data.labels
                this.setExtraDictionaries(data)

                if (this.id) {
                    if (this.isPageForm) {
                        this.$store.commit('setDashboardTitle',this.pageTitleEdit+' ID' + this.id)
                    }
                    axios.get(this.$store.getters.getApiUrl(this.pageApi, this.id))
                    .then((response) => {
                        //console.log(response.headers)
                        let data = response.data

                        if (data.success !== undefined && data.success === false) {
                            this.$store.commit('setNotification', {
                                type: 'error',
                                message: data.message ?? 'Load error'
                            })
                            return;
                        }

                        this.element = data.element

                        this.setExtraData(data)

                        for (let key in this.element) {
                            if (this.selectKeys.includes(this.getTypeForm(key))) {
                                this.setValueSelect(this.element, key)
                            }
                            if (this.getTypeForm(key) === 'boolean') {
                                switch (this.element[key]) {
                                    case 0:
                                        this.element[key] = false
                                        break;
                                    case 1:
                                        this.element[key] = true
                                        break;
                                }

                                this.element[key] = this.selectBooleanOptions.find(item => item.id === this.element[key])
                            }
                        }
                    }).catch(error => {
                        //console.log(error)
                    })
                } else {
                    this.labels.map(label => {
                        if (!this.selectKeys.includes(this.typeForm[label])
                            //&& this.typeForm[label] !== 'date'
                        ) {
                            this.element[label] = null
                        }
                    })
                    this.setExtraDataCreate()
                }
            })
            .catch(error => {
                //console.log(error)
            })
            .finally(() => {
                this.$store.commit('setLoading', false)
            })
        },

        setExtraDataCreate() {},

        setExtraDictionaries(data) {},

        setExtraData(data) {},

        getSelectLabel(label) {
            if (label === 'user_id' || label === 'parent_id') {
                return 'name'
            }
            return 'title'
        },

        goToList() {
            this.$router.push({ name: this.pageRouteList })
        },

        setLang() {
            if (!this.lang) {
                return
            }

            this.form_labels = this.lang.form
            this.pageTitle = this.lang.title_page
            this.pageTitleCreate = this.lang.title_create_page
            this.pageTitleEdit = this.lang.title_edit_page

            this.buttons.map(item => {
                if (this.lang.buttons[item.key]) {
                    item.label = this.lang.buttons[item.key]
                }

                return item
            })
        },

        getClass(key) {
            return 'w100'
        },

        // Установить значение для атрибута выпадающего списка
        setValueSelect(model, key) {
            let dictionary_key = key
            if (this.typeForm[key].type && this.typeForm[key].type === 'custom-select') {
                dictionary_key = this.typeForm[key].dictionary
            }

            if (this.dictionaries[dictionary_key]) {
                let index = this.dictionaries[dictionary_key].findIndex(item => item.id === model[key])
                if (index !== -1) {
                    model[key] = this.dictionaries[dictionary_key][index]
                    return this.dictionaries[dictionary_key][index]
                }
            }

            return null
        },

        // Получить значение для атрибута выпадающего списка
        getValueSelect(model, key) {
            if (model[key]) {
                model[key] = model[key].id
            }
        },

        // Получить тип поля
        getTypeForm(key) {
            if (this.typeForm[key]) {
                if (typeof this.typeForm[key] === 'string') {
                    return this.typeForm[key]
                } else {
                    return this.typeForm[key].type
                }
            }
            return 'input'
        },

        // Удаление объекта
        deleteForm() {
            this.$store.commit('setLoading', true)
            axios.delete(this.$store.getters.getApiUrl(this.pageApi, this.id))
                .then(({data}) => {
                    if (this.isPageForm) {
                        this.goToList()
                    } else {
                        this.$store.commit('setLoading', false)
                        this.openConfirmDelete = false
                        this.$emit('updateElement', null)
                        this.setNullForm()
                    }
                })
        },

        setNullForm() {
            this.id = null
            for (let key in this.element) {
                this.element[key] = null
            }
        },

        // Добавить данные для сохранения
        appendDataSave() {
            return {}
        },

        // Сохранение формы
        saveForm() {
            let access = false
            if (this.id) {
                access = this.getPermissionSection('edit')
            } else {
                access = this.getPermissionSection('create')
            }
            if (!access) {
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: 'Доступ ограничен'
                })
                return;
            }

            let data = {
                element: {},
            }
            let append = this.appendDataSave()
            for (let key in append) {
                data[key] = append[key]
            }

            for (let key in this.element) {
                if (this.form_required.includes(key) && !this.element[key]) {
                    this.$store.commit('setNotification', {
                        type: 'error',
                        message: this.$ml.get('interface.empty_field') + ' ' + this.lang.form[key]
                    })
                    return
                }

                data.element[key] = this.element[key]
                if (this.selectKeys.includes(this.getTypeForm(key))) {
                    this.getValueSelect(data.element, key)
                }
                if (this.getTypeForm(key) === 'boolean') {
                    if (this.element[key]) {
                        data.element[key] = this.element[key].id
                    }
                }
            }


            if (this.formUploadFiles) {
                let formData = new FormData();

                for (let key in this.uploadFiles) {
                    if (this.uploadFiles[key]) {
                        formData.append(`upload[${key}]`, this.uploadFiles[key])
                    }
                }

                for (let key in data) {
                    if (key === 'files') {
                        data[key].map(file => {
                            formData.append(`files[${file.id}]`, file.file)
                        })
                    } else {
                        formData.append(key, JSON.stringify(data[key]))
                    }
                }
                data = formData
            }

            this.$store.commit('setLoading', true)
            this.getRequest(data)
                .then(({data}) => {
                    if (data.success) {
                        if (!this.id) {
                            this.id = data.element.id
                            if (this.isPage) {
                                this.$router.push({name: this.pageRouteEdit, params: {id: data.element.id}})
                            }
                        }

                        this.$emit('updateElement', data.element)

                        if (this.formUploadFiles) {
                            for (let key in this.uploadFiles) {
                                this.uploadFiles[key] = null
                            }
                        }

                        this.getData()
                        this.$store.commit('setNotification', {
                            type: 'success',
                            message: this.$ml.get('interface.saved')
                        })
                    } else {
                        this.$store.commit('setErrorMessage', data.message)
                    }
                }).catch((error) => {
                    console.log(error)
                    this.$store.commit('setNotification', {
                        type: 'error',
                        message: 'Ошибка сервера. Обратитесь к администратору.'
                    })
                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                })
        },

        // Получить объект запроса для формы
        getRequest(data) {
            if (this.formUploadFiles) {
                return axios.post(this.$store.getters.getApiUrl(this.pageApi), data)
            }

            if (this.id) {
                return axios.put(this.$store.getters.getApiUrl(this.pageApi, this.id), data)
            }
            return axios.post(this.$store.getters.getApiUrl(this.pageApi), data)
        }
    }
}
</script>

<style scoped>

</style>
