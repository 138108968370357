<template>
    <div class="modalWindow successModal" ref="modal">
        <div class="modalClose" @click="closeModal"></div>
        <div :class="['modalContainer', {'large' : isLarge }, {'full-window' : fullWindow }]">
            <div class="header">
                <slot name="title"></slot>
                <div class="close" @click="closeModal"></div>
            </div>
            <div class="body">
                <slot name="body"></slot>
            </div>
            <div class="footer">
                <slot name="buttons"></slot>
                <button class="button" @click="closeModal">
                    {{ $ml.get('interface.close') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalWindow",
    props: {
        open: {
            type: Boolean,
            default: null
        },
        isLarge: {
            type: Boolean,
            default: false
        },
        fullWindow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        openModal() {
            this.$refs.modal.classList.add('active');
        },
        closeModal() {
            this.isOpen = null
            this.$emit('closeModal')
            this.$refs.modal.classList.remove('active');
        },
    },
    computed: {},
    watch: {
        open (newVal, oldVal) {
            if (newVal !== null && newVal !== false) {
                this.isOpen = newVal
                this.openModal()
            } else {
                this.closeModal()
            }
        }
    }
}
</script>

<style scoped>

</style>
