export default {
    title_list: 'Платежи по договорам',
    title_page: 'Платеж по договору',
    title_create_page: 'Создать платеж по договору',
    title_edit_page: 'Изменить платеж по договору',

    filter: {
        labels: {
            user_id: 'Сотрудник',
            number: 'Номер платежа',
        }
    },

    table: {
        labels: {
            id: 'ID',
            user_id: 'Сотрудник',
            contract_id: 'Номер договора',
            number: 'Номер платежа',
            amount: 'Сумма платежа',
            note: 'Примечание',
            created_at: 'Дата создания',
        }
    },

    buttons: {

    },

    form: {
        user_id: 'Сотрудник',
        contract_id: 'Номер договора',
        number: 'Номер платежа',
        amount: 'Сумма платежа',
        note: 'Примечание',
        created_at: 'Дата создания',
    },
}
