import Login from './components/Auth/Login.vue'
import ForgotPassword from './components/Auth/ForgotPassword.vue'
import ResetPassword from './components/Auth/ResetPassword.vue'
import VerifyEmail from './components/Auth/VerifyEmail.vue'
import Register from './components/Auth/Register.vue'

import Layout from './components/Layout.vue'
import Dashboard from './components/Dashboard.vue'

import PermissionDenied from './components/Pages/PermissionDenied.vue'

import UserForm from "./components/User/User/Form.vue";
import UserList from "./components/User/User/List.vue";

import UserRoleList from "./components/User/Role/List.vue";
import UserRoleForm from "./components/User/Role/Form.vue";

import UserPermissionList from "./components/User/Permission/List.vue";
import UserPermissionForm from "./components/User/Permission/Form.vue";

import UserPositionList from "./components/User/Position/List.vue";
import UserPositionForm from "./components/User/Position/Form.vue";

import UserContractList from "./components/User/Contract/List.vue";
import UserContractForm from "./components/User/Contract/Form.vue";

import UserContractPaymentList from "./components/User/ContractPayment/List.vue";
import UserContractPaymentForm from "./components/User/ContractPayment/Form.vue";

import ProjectList from "./components/Project/Project/List.vue";
import ProjectForm from "./components/Project/Project/Form.vue";

import ProjectStatusList from "./components/Project/Status/List.vue";
import ProjectStatusForm from "./components/Project/Status/Form.vue";

import ProjectContractList from "./components/Project/Contract/List.vue";
import ProjectContractForm from "./components/Project/Contract/Form.vue";


import { store } from './store'


const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}
const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}

export const routes = [

    // Авторизация
    { path: '/login', component: Login, name: 'auth.login', beforeEnter: ifNotAuthenticated },
    //{ path: '/register', component: Register, name: 'auth.register', beforeEnter: ifNotAuthenticated },
    { path: '/forgot-password', component: ForgotPassword, name: 'auth.forgot_password', beforeEnter: ifNotAuthenticated },
    //{ path: '/password/reset/:token', component: ResetPassword, name: 'auth.reset_password', beforeEnter: ifNotAuthenticated, props: true },

    { path: '/email/verify/:id/:hash', component: VerifyEmail, name: 'auth.verify_email', props: true },

    // Панель управления
    { path: '/', component: Layout, beforeEnter: ifAuthenticated, children: [
            { path: '', component: Dashboard, name: 'dashboard' },

            { path: '/permission-denied', component: PermissionDenied, name: 'permission.denied' },

            // Пользователи
            { path: 'user', component: UserList, name: 'user' },
            { path: 'user/create', component: UserForm, name: 'user.create' },
            { path: 'user/:id/edit', component: UserForm, name: 'user.edit' },

            // Должности
            { path: 'position', component: UserPositionList, name: 'position' },
            { path: 'position/create', component: UserPositionForm, name: 'position.create' },
            { path: 'position/:id/edit', component: UserPositionForm, name: 'position.edit' },

            // Договора
            { path: 'contract', component: UserContractList, name: 'contract' },
            { path: 'contract/create', component: UserContractForm, name: 'contract.create' },
            { path: 'contract/:id/edit', component: UserContractForm, name: 'contract.edit' },

            // Платежи по договорам пользователей
            { path: 'user-contract-payment', component: UserContractPaymentList, name: 'user.contract.payment' },
            { path: 'user-contract-payment/create', component: UserContractPaymentForm, name: 'user.contract.payment.create' },
            { path: 'user-contract-payment/:id/edit', component: UserContractPaymentForm, name: 'user.contract.payment.edit' },

            // Роли
            { path: 'roles', component: UserRoleList, name: 'role' },
            { path: 'roles/create', component: UserRoleForm, name: 'role.create' },
            { path: 'roles/:id/edit', component: UserRoleForm, name: 'role.edit' },

            // Права
            { path: 'permissions', component: UserPermissionList, name: 'permission' },
            { path: 'permissions/create', component: UserPermissionForm, name: 'permission.create' },
            { path: 'permissions/:id/edit', component: UserPermissionForm, name: 'permission.edit' },

            // Проекты
            { path: 'projects', component: ProjectList, name: 'project' },
            { path: 'projects/create', component: ProjectForm, name: 'project.create' },
            { path: 'projects/:id/edit', component: ProjectForm, name: 'project.edit' },

            // Статусы проектов
            { path: 'projects-statuses', component: ProjectStatusList, name: 'project.status' },
            { path: 'projects-statuses/create', component: ProjectStatusForm, name: 'project.status.create' },
            { path: 'projects-statuses/:id/edit', component: ProjectStatusForm, name: 'project.status.edit' },

            // Договора проектов
            { path: 'projects-contracts', component: ProjectContractList, name: 'project.contract' },
            { path: 'projects-contracts/create', component: ProjectContractForm, name: 'project.contract.create' },
            { path: 'projects-contracts/:id/edit', component: ProjectContractForm, name: 'project.contract.edit' },
        ]
    },



]
