import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        url: '/',
        url_api: '/api/',

        api_route: {
            auth: 'auth',

            homePage: 'homePage',
            dashboard: 'dashboard',

            user: 'user',
            role: 'role',
            permission: 'permission',
            position: 'position',
            contract: 'contract',
            'user.contract.payment': 'userContractPayment',

            project: 'project',
            'project.contract': 'projectContract',
            'project.status': 'projectStatus',
            'project.folder': 'projectFolder',
            'project.users': 'projectUser',
            'project.file': 'projectFile',

            menu: 'menu',
            page: 'page',
            'page.upload_image': 'uploadContentImage',
            category: 'category',
            settings: 'settings',
            document: 'document',
            'document.category': 'documentCategory',

            service: 'service',
            'service.category': 'serviceCategory',

            cargo: 'cargo',
            'cargo.issue': 'cargoIssue',
            'cargo.status': 'cargoStatus',
            'cargo.export': 'cargoExport',
            'cargo.sync': 'cargoSync',

            'request.delivery': 'deliveryRequest',
            'request.cargo_issue': 'cargoIssueRequest',


        },
        api_token: null,
        loadingData: false,
        errorNotify: null,
        successNotify: null,
        notifyConfirm: {
            content: null,
            action: null
        },
        notification: null,
        systemTitle: 'AGS Админ',
        dashboardTitle: 'Панель управления',
        user: {
            short_name: ''
        },
        userPermissions: [],
        userRoles: [],
        currencies: [],
    },
    actions: {
        AUTH_LOGIN ({ commit }, payload) {
            commit('AUTH_LOGIN', payload)
        },
        AUTH_LOGOUT ({ commit }) {
            commit('AUTH_LOGOUT')
        }
    },
    mutations: {
        AUTH_LOGIN(state, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload
            Vue.$cookies.set('api_token', payload)
            state.api_token = payload
        },
        AUTH_LOGOUT(state) {
            Vue.$cookies.remove('api_token')
            state.api_token = null
        },
        setUser(state, payload) {
            state.user = payload
        },
        setCurrency(state, payload) { state.currencies = payload },
        setRoles(state, payload) { state.userRoles = payload },
        setPermissions(state, payload) { state.userPermissions = payload },
        setDashboardTitle(state, payload) {
            document.title = payload + ' | ' + state.systemTitle ?? 'CRM | ' + state.systemTitle
            state.dashboardTitle = payload
        },
        setApiToken(state, payload) {
            state.api_token = payload
        },
        setErrorMessage(state, payload) {
            state.errorNotify = payload
        },
        setSuccessMessage(state, payload) {
            state.successNotify = payload
        },
        setNotification(state, payload) {
            payload.id = Math.random() + '_' + Date.now()
            state.notification = payload
        },
        setLoading(state, payload) {
            state.loadingData = payload
        }
    },
    getters: {


        isAuthenticated: state => {
            return !!state.api_token
        },
        getLoading: state => {
            return state.loadingData
        },
        getErrorMessage: state => {
            return state.errorNotify
        },
        getNotification: state => {
            return state.notification
        },
        getSuccessMessage: state => {
            return state.successNotify
        },
        getDashboardTitle: state => {
            return state.dashboardTitle
        },
        getApiUrl: state => (type, param = null) => {
            if (param !== null) {
                return state.url_api + state.api_route[type] + '/' + param
            } else {
                return state.url_api + state.api_route[type];
            }
        },
        isAdmin: state => () => {
            if (state.userRoles.includes('super_admin')) {
                return true
            }
            return false
        },
        checkPermission: state => (key) => {
            if (state.userRoles.includes('super_admin')) {
                return true
            }
            if (state.userPermissions.includes(key)) {
                return true
            }
            return false
        },
        getApiToken: state => {
            return state.api_token;
        },
    }
})
