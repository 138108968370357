<template>
    <div class="authPage">

        <div class="title">
            {{ $store.state.systemTitle }}
        </div>

        <div class="loginForm">
            <div class="formBlock">
                <p></p>
                <p class="errorNotify">Для восстановления пароля - свяжитесь с ответственным лицом вашей компании</p>
            </div>
        </div>
        <div class="links">
            <router-link :to="{name: 'auth.login'}">Войти</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "ForgotPassword",
    data() {
        return {

        }
    },
    methods: {

    },
    watch: {

    }
}
</script>

<style scoped>

</style>
