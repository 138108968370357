<template>
    <thead :key="counter">
    <tr>
        <template v-for="(item, index) in header">

            <th :colspan="item.colspan ? item.colspan : 1" @click="sortBy(item, index)" :class="[
                {'width_fixed' : item.width_fixed },
                {'active': item.sort && item.sort !== null },
                [ item.width && item.width !== null ? 'w'+item.width : '' ],
                ]">
                <div :class="['table__title', item.classes ]">
                    <template v-if="isCheckedAll && item.key === 'id'">
                        <input type="checkbox" v-model="checkedAll">
                    </template>
                    <template v-else>
                        <div class="label" v-html="item.label"></div>
                        <template v-if="item.is_sort">
                            <div class="sort">
                                <template v-if="!item.sort">
                                    <span class="icon ico-fa icon-sort"></span>
                                </template>
                                <template v-if="item.sort === 'asc'">
                                    <span class="icon ico-fa icon-asc"></span>
                                </template>
                                <template v-if="item.sort === 'desc'">
                                    <span class="icon ico-fa icon-desc"></span>
                                </template>
                            </div>
                        </template>
                    </template>

                </div>
            </th>
        </template>
    </tr>
    </thead>
</template>

<script>
export default {
    name: "Thead",
    props: {
        isCheckedAll: {
            type: Boolean,
            default: false,
        },
        header: {
            type: Array,
            default: []
        },
        isCounter: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            counter: 0,
            checkedAll: false,
        }
    },
    watch: {
        checkedAll(val) {
            this.$emit('checkedAll', val)
        },
        isCounter(newVal, oldVal) {
            this.counter++
        }
    },
    methods: {
        sortBy(item, index) {
            if (this.isCheckedAll && item.key === 'id') {
                return
            }

            if (item.is_sort && item.key) {
                let sort = ''
                if (item.sort === null || item.sort === undefined) {
                    sort = 'asc'
                } else if (item.sort === 'asc') {
                    sort = 'desc'
                } else {
                    sort = null
                }
                this.$emit('sortBy', index, sort)
                this.counter++
            }
        }
    }
}
</script>

<style scoped>

</style>
