<template>
    <div :class="['slideModal', {'open' : open}]">
        <div class="slideModalClose" @click="returnBack()"></div>
        <div class="modalContainer">
            <div class="head">
                <div class="title">
                    {{ titleModal }}
                </div>
            </div>

            <div class="body" style="height: 100%;">
                <div class="back" style="margin-bottom: 10px">
                    <div class="icon" @click="returnBack()"></div>
                </div>

                <payment-form @updateElement="(el) => updateElement(el)" :element-id="elementId" :element-options="elementOptions" :is-page-form="false"/>

            </div>
        </div>
    </div>
</template>

<script>
import PaymentForm from '../ContractPayment/Form.vue'

export default {
    name: "CreatePayment",
    components: {
        PaymentForm
    },
    props: {
        modalOpen: {
            type: Boolean,
            default: false
        },
        elementOptions: {
            type: Object,
            default: () => { return {} }
        },
        elementId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            open: this.modalOpen,
            loading: false,

            element: null,
        }
    },
    watch: {
        modalOpen(val) {
            this.open = val
        }
    },
    computed: {
        titleModal() {
            if ((this.element && this.element.id) || this.elementId) {
                return `Изменить платеж ID ${ this.element?.id ?? this.elementId }`
            }
            return 'Создать платеж'
        }
    },
    methods: {
        updateElement(el) {
            this.element = el
            if (!el) {
                this.returnBack()
            }
            this.$emit('updateElement', el)
        },
        returnBack() {
            this.open = false
            this.$emit('close')
        },
    }
}
</script>


<style scoped>

</style>
