<template>
    <div class="authPage">

        <div class="title">
            {{ $store.state.systemTitle }}
        </div>

        <div class="loginForm">
            <div class="formBlock">
                <p class="errorNotify" v-if="showError">{{ errorNotify }}</p>
                <p class="successNotify" v-if="showSuccess">{{ successNotify }}</p>
            </div>
        </div>
        <div class="links">
            <router-link :to="{name: 'auth.login'}">Войти</router-link>
            <router-link :to="{name: 'auth.register'}">Зарегистрироваться</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "VerifyEmail",
    data() {
        return {
            user: {
                expires: this.$route.query.expires,
                signature: this.$route.query.signature,
                hash: this.$route.params.hash,
                id: this.$route.params.id,
            },
            labels: {
                //email: 'Email',
            },
            type: 'VerifyEmail',
            showError: false,
            errorNotify: null,
            showSuccess: false,
            successNotify: null,

            isVerify: null,
        }
    },
    mounted() {
        this.enter();
    },
    methods: {
        goToLogin() {
            this.$router.push({name: 'auth.login'})
        },
        enter() {
            this.errorNotify = null
            this.successNotify = 'Подтверждаем email...'
            axios.post('/api/auth', {
                type: this.type,
                user: this.user,
            })
            .then(({data}) => {
                if (data.success) {
                    this.successNotify = data.message
                } else {
                    this.successNotify = null
                    this.errorNotify = data.message
                }
            })
            .catch((error) => {
                this.successNotify = null
                this.errorNotify = 'Произошла ошибка'
            })
        },
    },
    watch: {
        errorNotify(val) {
            if (val !== null) {
                this.showError = true
            } else {
                this.showError = false
            }
            this.errorNotify = val
        },
        successNotify(val) {
            if (val !== null) {
                this.showSuccess = true
            } else {
                this.showSuccess = false
            }
            this.successNotify = val
        }
    }
}
</script>

<style scoped>

</style>
