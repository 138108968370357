<template>
    <div class="default-page">

        <div class="buttons">
            <button class="button btn-primary"
                    @click="saveForm">
                {{ $ml.get('buttons.save') }}
            </button>

            <button class="button btn-primary" @click="goToList">
                {{ $ml.get('buttons.back') }}
            </button>

            <button class="button btn-primary" @click="openPaymentModal()" v-if="this.element && this.element.id">
                Создать платеж
            </button>

            <button class="button btn-danger"
                    v-if="this.element && this.element.id && getPermissionSection('delete')"
                    @click="openConfirmDelete = true">
                {{ $ml.get('buttons.delete') }}
            </button>

            <button v-for="(button, index) in buttons"
                    :class="['button', button.class]"
                    @click="button.function">
                {{ button.label }}
            </button>
        </div>

        <div class="block" :key="change">
            <div class="title">{{ pageTitle }}</div>
            <div class="body flex-row-wrap">
                <div :class="getClass(label)"
                     v-for="(label, key) in labels">
                    <div class="formBlock">
                        <p :class="['label', {'required' : form_required.includes(label)}]">
                            {{ form_labels[label] }}
                            <template v-if="form_required.includes(label)">*</template>
                        </p>
                        <template v-if="getTypeForm(label) === 'number'">
                            <input type="number"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>

                        <template v-if="getTypeForm(label) === 'mask'">
                            <input type="text"
                                   class="input"
                                   v-maska
                                   :data-maska="typeForm[label].mask"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>

                        <template v-if="getTypeForm(label) === 'string'">
                            <div class="input disabled" v-if="element[label]">{{ element[label] }}</div>
                        </template>
                        <template v-if="getTypeForm(label) === 'date-string'">
                            <div class="input disabled">
                                <template v-if="element[label]">
                                    {{ $func.getDateTime(element[label]) }}
                                </template>
                                <template v-else>&nbsp;</template>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'date'">
                            <date-picker
                                lang="ru"
                                valueType="format"
                                format="DD.MM.YYYY"
                                v-model="element[label]"
                                :placeholder="form_labels[label]"
                            />
                        </template>
                        <template v-if="getTypeForm(label) === 'datetime'">
                            <date-picker
                                lang="ru"
                                type="datetime"
                                valueType="format"
                                format="DD.MM.YYYY HH:mm:ss"
                                v-model="element[label]"
                                :placeholder="form_labels[label]"
                            />
                        </template>
                        <template v-if="getTypeForm(label) === 'link'">
                            <textarea
                                class="textarea"
                                v-model="element[label]"
                                :placeholder="form_labels[label]">
                            </textarea>
                        </template>
                        <template v-if="getTypeForm(label) === 'textarea'">
                            <textarea
                                class="textarea"
                                v-model="element[label]"
                                :placeholder="form_labels[label]">
                            </textarea>
                        </template>
                        <template v-if="getTypeForm(label) === 'input'">
                            <input type="text"
                                   class="input"
                                   v-model="element[label]"
                                   :placeholder="form_labels[label]">
                        </template>
                        <template v-if="getTypeForm(label) === 'file'">
                            <input v-if="!uploadFiles[label]"
                                   type="file"
                                   class="input"
                                   v-on:change="(event) => onChangeFile(event, label)"
                                   :placeholder="form_labels[label]">
                            <template v-if="uploadFiles[label] && uploadFiles[label].name">
                                <span class="input">
                                    Файл для загрузки: {{ uploadFiles[label].name }}
                                    (<span class="link" @click="uploadFiles[label] = null">Сбросить</span>)
                                </span>
                            </template>
                            <template v-if="element[label]">
                                <span class="input">
                                    Загруженный файл: <a :href="element[label]" target="_blank" class="link">{{ element[label] }}</a>
                                </span>
                            </template>

                        </template>
                        <template v-if="getTypeForm(label) === 'select'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    :label="getSelectLabel(label)"
                                    v-model="element[label]"
                                    :options="dictionaries[label]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'boolean'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    label="title"
                                    v-model="element[label]"
                                    :options="selectBooleanOptions"
                                    :placeholder="form_labels[label]">
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'color'">
                            <photoshop-picker v-model="element[label]" />
                        </template>
                        <template v-if="getTypeForm(label) === 'tinymce'">
                            <Editor :api-key="tinyMceApi" :init="tinyMceConfig" v-model="element[label]" />
                        </template>
                        <template v-if="getTypeForm(label) === 'custom-select'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    :label="typeForm[label].label"
                                    :value="element[label]"
                                    v-model="element[label]"
                                    :options="dictionaries[typeForm[label].dictionary]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                        <template v-if="getTypeForm(label) === 'select-tags'">
                            <div class="defaultMultiselect">
                                <Multiselect
                                    track-by="id"
                                    label="title"
                                    :multiple="true"
                                    :taggable="true"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :value="element[label]"
                                    v-model="element[label]"
                                    :options="dictionaries[label]"
                                    :placeholder="form_labels[label]">
                                    <template slot="noResult">
                                        {{ $ml.get('interface.no_result') }}
                                    </template>
                                    <template slot="noOptions">
                                        {{ $ml.get('interface.no_options') }}
                                    </template>
                                </Multiselect>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="block" v-if="element.id">
            <div class="title">Платежи по договору</div>
            <div class="body">
                <div class="table-scroll__wrapper">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Номер платежа</th>
                            <th>Сумма</th>
                            <th>Дата платежа</th>
                            <th>Примечание</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in payments">
                            <td><span @click="openPaymentModal(item)" class="link">{{ item.id }}</span></td>
                            <td class="text-l">{{ item.number }}</td>
                            <td class="text-r">{{ item.amount }}</td>
                            <td>{{ dayjs(item.created_at).format('DD.MM.YYYY HH:mm:ss') }}</td>
                            <td class="wrap">{{ item.note }}</td>
                        </tr>
                        <tr v-if="!payments.length">
                            <td colspan="5">Нет ни одного платежа</td>
                        </tr>

                        <tr>
                            <td colspan="2" class="text-r"><b>Сумма платежей:</b></td>
                            <td class="text-r">{{ amountPayments }}</td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-r"><b>Сумма по договору:</b></td>
                            <td class="text-r">{{ element.amount }}</td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-r"><b>Осталось выплатить:</b></td>
                            <td class="text-r">{{ balancePayments }}</td>
                            <td colspan="2"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <ModalWindow :open="openConfirmDelete"
                     @closeModal="openConfirmDelete = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                {{ $ml.get('interface.confirm_action_delete') }}
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteForm">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>

        <CreatePayment @updateElement="getData"
                       :element-id="paymentModalId"
                       :modal-open="paymentModal"
                       :element-options="paymentModalOptions"
                       @close="paymentModal = false">
        </CreatePayment>

    </div>
</template>

<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";
import dayjs from "dayjs";
import CreatePayment from "./CreatePayment.vue";

export default {
    name: "Form",
    components: {CreatePayment},
    mixins: [FormDefault],
    data() {
        return {
            lang: this.$ml.get('pages.contract'),
            buttons: [
            ],

            typeForm: {
                user_id: 'select',
                end_date: 'date',
                active: 'boolean',
                note: 'textarea',
                is_unlimited: 'boolean',
                amount: 'number',
            },

            element: {
                end_date: null,
                active: null,
                is_unlimited: null,
            },

            pageRouteList: 'contract',
            pageRouteEdit: 'contract.edit',
            pageApi: 'contract',
            pagePermission: 'user.contract',

            paymentModal: false,
            paymentModalOptions: {},
            paymentModalId: null,
            payments: [],
        }
    },
    computed: {
        amountPayments() {
            let amount = 0
            this.payments.map(el => {
                amount += Number(el.amount)
            })
            return Math.ceil(amount * 100) / 100
        },
        balancePayments() {
            let balance = Number(this.element.amount) - Number(this.amountPayments)
            return Math.ceil(balance * 100) / 100
        }
    },
    methods: {
        openPaymentModal(item = null) {
            if (!item) {
                this.paymentModalId = null
                this.paymentModalOptions = {
                    user_id: this.element.user_id,
                    contract_id: this.id,
                }
            } else {
                this.paymentModalOptions = {}
                this.paymentModalId = item.id
            }

            this.paymentModal = true
        },
        dayjs,
        getSelectLabel(label) {
            return 'all_name'
        },
        getClass(label) {
            if (label === 'note') {
                return 'w100'
            }
            return 'w50'
        },
        setExtraData(data) {
            this.payments = data.payments
        }
    }
}
</script>

<style scoped>

</style>
