export default {
    title_list: 'Настройки сайта',
    title_page: 'Настройка сайта',
    title_create_page: 'Создать настройку сайта',
    title_edit_page: 'Изменить настройку сайта',

    labels: {

    },

    filter: {
        labels: {
            id: 'ID',
            slug: 'Ключ',
            value: 'Значение на русском',
        }
    },

    table: {
        labels: {
            id: 'ID',
            slug: 'Ключ',
            value: 'Значение на русском',
            value_en: 'Значение на английском',
        }
    },

    buttons: {

    },

    form: {
        slug: 'Ключ',
        value: 'Значение на русском',
        value_en: 'Значение на английском',
    },
}
