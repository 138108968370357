<script>
import ListDefault from "../../Utils/Page/ListDefault.vue";

export default {
    name: "List",
    mixins: [ListDefault],
    data() {
        return {
            lang: this.$ml.get('pages.position'),

            filter: {
                title: { value: null, type: 'text'},
            },
            tableHeader: [
                { key: 'id', is_sort: true },
                { key: 'title', is_sort: true, classes: 'text-l' },
            ],

            apiList: this.$store.getters.getApiUrl('position'),
            pageRoute: 'position',
            pageRouteCreate: 'position.create',
            pageRouteEdit: 'position.edit',

            pagePermission: 'user.position',
        }
    },
}
</script>

<style scoped>

</style>
