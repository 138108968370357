export default {
    search: 'Поиск',
    search_open: 'Нажмите чтобы развернуть',

    not_selected: 'Не выбрано',
    selected: 'Выбран',
    select_value: 'Выберите значение',
    no_result: 'Нет подходящих результатов',
    no_options: 'Нет вариантов для выбора',

    saved: 'Сохранено',
    deleted: 'Удалено',
    empty_field: 'Не заполнено обязательное поле',

    delete: 'Удалить',
    edit: 'Изменить',
    view: 'Просмотр',

    user: 'Пользователь',
    status: 'Статус',
    date: 'Дата',

    error: 'Произошла ошибка',
    success: 'Успешно',
    cancel: 'Закрыть',
    close: 'Закрыть',
    send: 'Отправить',

    yes: 'Да',
    no: 'Нет',
    rejected: 'Отклонен',

    confirm_action: 'Подтвердите действие',
    confirm_action_delete: 'Вы уверены, что хотите удалить выбранный элемент?',
}
