<template>
    <div class="default-page">

        <div class="buttons">
            <button class="button btn-primary"
                    v-if="getPermissionSection('edit') || getPermissionSection('create')"
                    @click="saveForm">
                {{ $ml.get('buttons.save') }}
            </button>

            <button class="button btn-primary" @click="goToList">
                {{ $ml.get('buttons.back') }}
            </button>

            <button class="button btn-danger"
                    v-if="this.element && this.element.id && getPermissionSection('delete')"
                    @click="openConfirmDelete = true">
                {{ $ml.get('buttons.delete') }}
            </button>

            <button v-for="(button, index) in buttons"
                    :class="['button', button.class]"
                    @click="button.function">
                {{ button.label }}
            </button>
        </div>

        <!-- Основные данные -->
        <div class="block" :key="change">
            <div class="title">{{ pageTitle }}</div>
            <div class="body flex-row-wrap">
                <div :class="getClass(label)"
                     v-for="(label, key) in labels">
                    <div class="formBlock">
                        <p :class="['label', {'required' : form_required.includes(label)}]">
                            {{ form_labels[label] }}
                            <template v-if="form_required.includes(label)">*</template>
                        </p>

                        <template v-if="getPermissionSection('edit')">
                            <template v-if="getTypeForm(label) === 'number'">
                                <input type="number"
                                       class="input"
                                       v-model="element[label]"
                                       :placeholder="form_labels[label]">
                            </template>

                            <template v-if="getTypeForm(label) === 'mask'">
                                <input type="text"
                                       class="input"
                                       v-maska
                                       :data-maska="typeForm[label].mask"
                                       v-model="element[label]"
                                       :placeholder="form_labels[label]">
                            </template>

                            <template v-if="getTypeForm(label) === 'string'">
                                <div class="input disabled" v-if="element[label]">{{ element[label] }}</div>
                            </template>

                            <template v-if="getTypeForm(label) === 'date-string'">
                                <div class="input disabled">
                                    <template v-if="element[label]">
                                        {{ $func.getDateTime(element[label]) }}
                                    </template>
                                    <template v-else>&nbsp;</template>
                                </div>
                            </template>

                            <template v-if="getTypeForm(label) === 'date'">
                                <date-picker
                                    lang="ru"
                                    valueType="format"
                                    format="DD.MM.YYYY"
                                    v-model="element[label]"
                                    :placeholder="form_labels[label]"
                                />
                            </template>

                            <template v-if="getTypeForm(label) === 'datetime'">
                                <date-picker
                                    lang="ru"
                                    type="datetime"
                                    valueType="format"
                                    format="DD.MM.YYYY HH:mm:ss"
                                    v-model="element[label]"
                                    :placeholder="form_labels[label]"
                                />
                            </template>

                            <template v-if="getTypeForm(label) === 'link'">
                                <textarea
                                    class="textarea"
                                    v-model="element[label]"
                                    :placeholder="form_labels[label]">
                                </textarea>
                            </template>

                            <template v-if="getTypeForm(label) === 'textarea'">
                                <textarea
                                    class="textarea"
                                    v-model="element[label]"
                                    :placeholder="form_labels[label]">
                                </textarea>
                            </template>

                            <template v-if="getTypeForm(label) === 'input'">
                                <input type="text"
                                       class="input"
                                       v-model="element[label]"
                                       :placeholder="form_labels[label]">
                            </template>

                            <template v-if="getTypeForm(label) === 'file'">
                                <input v-if="!uploadFiles[label]"
                                       type="file"
                                       class="input"
                                       v-on:change="(event) => onChangeFile(event, label)"
                                       :placeholder="form_labels[label]">
                                <template v-if="uploadFiles[label] && uploadFiles[label].name">
                                    <span class="input">
                                        Файл для загрузки: {{ uploadFiles[label].name }}
                                        (<span class="link" @click="uploadFiles[label] = null">Сбросить</span>)
                                    </span>
                                </template>
                                <template v-if="element[label]">
                                    <span class="input">
                                        Загруженный файл: <a :href="element[label]" target="_blank" class="link">{{ element[label] }}</a>
                                    </span>
                                </template>

                            </template>

                            <template v-if="getTypeForm(label) === 'select'">
                                <div class="defaultMultiselect">
                                    <Multiselect
                                        track-by="id"
                                        :label="getSelectLabel(label)"
                                        v-model="element[label]"
                                        :options="dictionaries[label]"
                                        :placeholder="form_labels[label]">
                                        <template slot="noResult">
                                            {{ $ml.get('interface.no_result') }}
                                        </template>
                                        <template slot="noOptions">
                                            {{ $ml.get('interface.no_options') }}
                                        </template>
                                    </Multiselect>
                                </div>
                            </template>

                            <template v-if="getTypeForm(label) === 'boolean'">
                                <div class="defaultMultiselect">
                                    <Multiselect
                                        track-by="id"
                                        label="title"
                                        v-model="element[label]"
                                        :options="selectBooleanOptions"
                                        :placeholder="form_labels[label]">
                                    </Multiselect>
                                </div>
                            </template>

                            <template v-if="getTypeForm(label) === 'color'">
                                <photoshop-picker v-model="element[label]" />
                            </template>

                            <template v-if="getTypeForm(label) === 'tinymce'">
                                <Editor :api-key="tinyMceApi" :init="tinyMceConfig" v-model="element[label]" />
                            </template>

                            <template v-if="getTypeForm(label) === 'custom-select'">
                                <div class="defaultMultiselect">
                                    <Multiselect
                                        track-by="id"
                                        :label="typeForm[label].label"
                                        :value="element[label]"
                                        v-model="element[label]"
                                        :options="dictionaries[typeForm[label].dictionary]"
                                        :placeholder="form_labels[label]">
                                        <template slot="noResult">
                                            {{ $ml.get('interface.no_result') }}
                                        </template>
                                        <template slot="noOptions">
                                            {{ $ml.get('interface.no_options') }}
                                        </template>
                                    </Multiselect>
                                </div>
                            </template>

                            <template v-if="getTypeForm(label) === 'select-tags'">
                                <div class="defaultMultiselect">
                                    <Multiselect
                                        track-by="id"
                                        label="title"
                                        :multiple="true"
                                        :taggable="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :preserve-search="true"
                                        :value="element[label]"
                                        v-model="element[label]"
                                        :options="dictionaries[label]"
                                        :placeholder="form_labels[label]">
                                        <template slot="noResult">
                                            {{ $ml.get('interface.no_result') }}
                                        </template>
                                        <template slot="noOptions">
                                            {{ $ml.get('interface.no_options') }}
                                        </template>
                                    </Multiselect>
                                </div>
                            </template>
                        </template>

                        <template v-else-if="getPermissionSection('view_list')">
                            <div class="input" v-if="['number', 'string', 'date-string', 'date', 'datetime', 'link', 'textarea', 'input'].includes(getTypeForm(label))">
                                {{ element[label] ?? '-' }}
                            </div>
                            <div class="input" v-if="['select'].includes(getTypeForm(label))">
                                {{ element[label][getSelectLabel(label)] }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <!-- Договора -->
        <div class="block" v-if="getPermissionSection('edit') && element.id">
            <div class="title">Договора по проекту</div>
            <div class="body">
                <div class="table-scroll__wrapper">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Номер договора</th>
                            <th>Дата окончания</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in contracts">
                            <td><a :href="getContractLink(item)" class="link">{{ item.id }}</a></td>
                            <td>{{ item.number }}</td>
                            <td>{{ $func.getDate(item.end_date) }}</td>
                        </tr>
                        <tr v-if="!contracts.length">
                            <td colspan="3">Нет ни одного договора</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Папки -->
<!--        <div class="block">
            <div class="title">Папки проекта</div>
            <div class="body">
                <div>
                    <button :class="['button', 'btn-primary']" @click="openModalWindowFolder()">
                        Добавить папку
                    </button>
                </div>
                <div class="table-scroll__wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in folders">
                                <td class="text-l wrap">{{ item.title }}</td>
                                <td>
                                    <button class="button btn-primary" @click="openModalWindowFolder(item)">
                                        <span class="icon ico-i icon-edit"></span>
                                    </button>
                                    <button class="button btn-danger" @click="openModalWindowDeleteFolder(item)">
                                        <span class="icon ico-i icon-delete"></span>
                                    </button>
                                </td>
                            </tr>
                            <tr v-if="!folders.length">
                                <td colspan="2">Нет ни одной папки</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>-->

        <!-- Права -->
        <div class="block" v-if="getPermissionSection('edit') && element.id">
            <div class="title">Права доступа к проекту</div>
            <div class="body">
                <div>
                    <button :class="['button', 'btn-primary']" @click="openModalUserFunction()">
                        Добавить пользователя
                    </button>
                </div>
                <div class="table-scroll__wrapper">
                    <table class="table" :key="permissionCounter">
                        <thead>
                        <tr>
                            <th class="text-l">Пользователь</th>
                            <th>Чтение</th>
                            <th>Запись</th>
                            <th>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in permissions" >
                            <td class="text-l">{{ item.user.all_name }}</td>
                            <td><input type="checkbox" v-model="item.read" @change="changePermission(item)"></td>
                            <td><input type="checkbox" v-model="item.write" @change="changePermission(item)"></td>
                            <td>
                                <button class="button btn-danger" @click="openConfirmDeleteUser(item)">
                                    <span class="icon ico-i icon-delete"></span>
                                </button>
                            </td>
                        </tr>
                        <tr v-if="!permissions.length">
                            <td colspan="3">Нет ни одного пользователя</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Файловый менеджер -->
        <file-manager :project-id="element?.id" v-show="element.id">

        </file-manager>


        <ModalWindow :open="openConfirmDelete"
                     @closeModal="openConfirmDelete = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                {{ $ml.get('interface.confirm_action_delete') }}
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteForm">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>


        <ModalWindow :open="openModalConfirmDeleteFolder"
                     @closeModal="openModalConfirmDeleteFolder = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                <template v-if="confirmDeleteFolder">
                    Вы действительно хотите удалить папку "{{ confirmDeleteFolder.title }}"?
                </template>
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deleteFolder(confirmDeleteFolder.id)">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>


        <ModalWindow :open="openModalConfirmDeleteUser"
                     @closeModal="openModalConfirmDeleteUser = false">
            <template slot="title">
                {{ $ml.get('interface.confirm_action') }}
            </template>
            <template slot="body">
                <template v-if="confirmDeleteUser">
                    Вы действительно хотите удалить разрешения для пользователя "{{ confirmDeleteUser.user.short_name }}"?
                </template>
            </template>
            <template slot="buttons">
                <button class="button btn-danger" @click="deletePermission(confirmDeleteUser)">
                    {{ $ml.get('buttons.delete') }}
                </button>
            </template>
        </ModalWindow>


<!--        <ModalWindow :open="openModalUser"
                     @closeModal="openModalUser = false">
            <template slot="title">
                Выбрать пользователя
            </template>
            <template slot="body">
                <div class="defaultMultiselect">
                    <Multiselect
                        track-by="id"
                        label="all_name"
                        v-model="selectUser"
                        :options="selectUsers"
                        placeholder="Выберите пользователя">
                        <template slot="noResult">
                            {{ $ml.get('interface.no_result') }}
                        </template>
                        <template slot="noOptions">
                            {{ $ml.get('interface.no_options') }}
                        </template>
                    </Multiselect>
                </div>
            </template>
            <template slot="buttons">
                <button class="button btn-success" @click="addUser">
                    Добавить
                </button>
            </template>
        </ModalWindow>-->

        <select-folder
            :project-id="element?.id"
            :modal-folder="modalFolder"
            :modal-open="slideModal"
            @updateRootFolders="(data) => updateRootFolders(data)"
            @close="slideModal = false; modalFolder = { title: null }">
        </select-folder>

        <select-users @selected="selectedUsers"
                      :modal-open="openModalUser"
                      @close="openModalUser = false">
        </select-users>

    </div>
</template>

<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";
import SelectFolder from "./SelectFolder.vue";

export default {
    name: "Form",
    components: {SelectFolder},
    mixins: [FormDefault],
    data() {
        return {
            lang: this.$ml.get('pages.project'),
            buttons: [
            ],

            typeForm: {
                user_id: 'select',
                status_id: 'select',
                end_date: 'date',
                note: 'textarea',
            },

            element: {
                end_date: null
            },

            pageRouteList: 'project',
            pageRouteEdit: 'project.edit',
            pageApi: 'project',
            pagePermission: 'project',

            folders: [],
            openModalFolder: false,
            openModalConfirmDeleteFolder: false,
            confirmDeleteFolder: null,
            modalFolder: {
                title: null
            },

            permissions: [],
            contracts: [],

            slideModal: false,

            openModalUser: false,
            selectUser: null,
            selectUsers: [],
            permissionCounter: 0,

            openModalConfirmDeleteUser: false,
            confirmDeleteUser: null,
        }
    },
    methods: {
        openConfirmDeleteUser(item) {
            this.confirmDeleteUser = item
            this.openModalConfirmDeleteUser = true
        },
        deletePermission(item) {
            let index = this.permissions.findIndex(el => el.user_id === item.user_id)
            if (index !== -1) {
                this.permissions.splice(index, 1)
            }
            this.openModalConfirmDeleteUser = false
        },
        updateRootFolders(folders) {
            this.folders = folders
        },
        appendDataSave() {
            return {
                permissions: this.permissions
            }
        },
        changePermission(item) {
            if (item.write) {
                item.read = true
            }
            this.permissionCounter++
        },
        selectedUsers(val) {
            val.map(el => {
                this.addUser(el)
            })
        },
        addUser(user) {
            let find = this.permissions.find(el => el.user_id === user.id)
            if (find) {
                this.$store.commit('setNotification', {
                    type: 'error',
                    message: `Пользователь ${ user.all_name } уже присутствует`
                })
            } else {
                this.permissions.push({
                    project_id: this.element.id,
                    user_id: user.id,
                    user: user,
                    read: true,
                    write: false,
                })
                this.openModalUser = false
            }
        },
        openModalUserFunction() {
            this.openModalUser = true
            /*this.$store.commit('setLoading', true)
            axios
                .get(this.$store.getters.getApiUrl('project.users'))
                .then(({ data }) => {
                    this.selectUsers = data.users

                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                })*/
        },
        openModalWindowDeleteFolder(item) {
            this.confirmDeleteFolder = item
            this.openModalConfirmDeleteFolder = true
        },
        openModalWindowFolder(item = null) {
            if (item) {
                let folder = this.folders.find(el => el.id === item.id)
                this.modalFolder = folder
            } else {
                this.modalFolder = {
                    title: null
                }
            }
            this.slideModal = true
        },
        deleteFolder(id) {
            this.$store.commit('setLoading', true)
            axios
                .delete(this.$store.getters.getApiUrl('project.folder', id))
                .then(({ data }) => {
                    this.folders = data.folders
                    this.openModalConfirmDeleteFolder = false
                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                })
        },
        saveFolder() {
            this.$store.commit('setLoading', true)
            axios
                .post(this.$store.getters.getApiUrl('project.folder'), {
                    folder: this.modalFolder,
                    project_id: this.element.id
                })
                .then(({ data }) => {
                    this.folders = data.folders

                    this.confirmDeleteFolder = null
                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                })
            this.openModalFolder = false
        },
        getContractLink(item) {
            let link = this.$router.resolve({ name: 'project.contract.edit', params: {id: item.id}})
            return link.href
        },
        setExtraData(data) {
            this.contracts = data.contracts
            this.folders = data.folders
            this.permissions = data.permissions
        },
        getClass(key) {
            if (['user_id', 'status_id', 'end_date'].includes(key)) {
                return 'w33'
            }
            return 'w100'
        },
        getSelectLabel(label) {
            if (label === 'user_id') {
                return 'all_name'
            }
            return 'title'
        },
    }
}
</script>

<style scoped>

</style>
<script setup>
import FileManager from "./FileManager.vue";
import SelectFile from "./SelectFile.vue";
import SelectUsers from "./SelectUsers.vue";
import Table from "../../Utils/Table/Table.vue";
</script>
