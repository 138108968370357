<script>
import FormDefault from "../../Utils/Page/FormDefault.vue";

export default {
    name: "Form",
    mixins: [FormDefault],
    data() {
        return {
            lang: this.$ml.get('pages.projectContract'),
            buttons: [
            ],

            typeForm: {
                project_id: 'select',
                end_date: 'date',
                active: 'boolean',
                note: 'textarea',
            },

            element: {
                end_date: null,
                active: null,
            },

            pageRouteList: 'project.contract',
            pageRouteEdit: 'project.contract.edit',
            pageApi: 'project.contract',
            pagePermission: 'project.contract',
        }
    },
    methods: {
        getSelectLabel(label) {
            return 'title'
        },
        getClass(label) {
            if (label === 'note') {
                return 'w100'
            }
            return 'w50'
        }
    }
}
</script>

<style scoped>

</style>
