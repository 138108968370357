export default {
    title_list: 'Пользователи',
    title_page: 'Пользователь',
    title_create_page: 'Создать пользователя',
    title_edit_page: 'Изменить пользователя',

    labels: {

    },

    filter: {
        labels: {
            id: 'ID',
            all_name: 'ФИО',
            position_id: 'Должность',
            type_labor_relation_id: 'Вид трудовых отношений',
        }
    },

    table: {
        labels: {
            id: 'ID',
            all_name: 'ФИО',
            email: 'Email',
            phone: 'Телефон',
            contract: 'Договор',
            position: 'Должность',
            labor_relation: 'Трудовые отношения',
        }
    },

    buttons: {

    },

    form: {
        surname: 'Фамилия',
        name: 'Имя',
        patronymic: 'Отчество',
        position_id: 'Должность',
        type_labor_relation_id: 'Форма трудовых отношений',
        email: 'Email',
        phone: 'Телефон',
        password: 'Пароль',
        email_verified_at: 'Дата подтверждения email',
        last_login_at: 'Дата последнего входа',
        language: 'Язык',
        activated: 'Активирован',

        passport_series: 'Серия паспорта',
        passport_number: 'Номер паспорта',
        passport_issue_date: 'Дата выдачи паспорта',
        passport_issue_org: 'Кем выдан паспорт',
        snils: 'СНИЛС',
        address: 'Адрес',
        dob: 'Дата рождения',

        inn: 'ИНН',
        kpp: 'КПП',

        bank: 'Банк',
        rs: 'р/с',
        ks: 'к/с',
        bik: 'БИК',
    },
}
